import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconCsa12 = ({ customStyle = null }) => (
  <svg viewBox="0 0 32 32" className={classNames(customStyle, styles.fill)}>
    <path d="M15.953 2C8.745 2 2.807 7.41 2 14.378h4.945v3.352h-4.93C2.87 24.646 8.78 30 15.952 30 23.713 30 30 23.734 30 15.998 30 8.268 23.712 2 15.953 2zm-2.97 22.663h-3.32V10.29H8.015V7.437h4.967v17.225zm11.2-2.974v2.973h-8.536l4.244-8.24c1.14-2.197 1.625-3.91 1.625-4.904 0-1.087-.46-1.692-1.26-1.692-1.284 0-1.526 1.016-1.526 3.478h-2.814c-.025-.242-.025-.48-.025-.7 0-3.55 1.6-5.385 4.558-5.385 2.545 0 4.17 1.62 4.17 4.25 0 2.27-1.188 4.613-2.836 7.56l-1.48 2.66h3.88z" />
  </svg>
)

IconCsa12.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCsa12

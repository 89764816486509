import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

import styles from "./index.css"

import routeHelpers from "helpers/route.js"
import { getAssetURL } from "helpers/static"
import { updateMeta, restoreDefaultMeta } from "helpers/meta"

import consts from "consts"
import i18n from "consts/i18n"
import routes from "consts/routes"

import { downloadLink } from "actions/download"
import selectorNavigator from "selectors/navigator"

import ContentHeader from "components/ContentHeader/ContentHeader"
import ContentBox from "components/ContentBox/ContentBox"
import DownloadStep from "components/DownloadStep/DownloadStep"
import Loader from "components/Loader/Loader"
import formatterHelper from "helpers/formatter"

const windowsDownloadImage = getAssetURL("image.pageDownloading.windowsDownloadImage")
const windowsInstallImage = getAssetURL("image.pageDownloading.windowsInstallImage")
const macInstallImage = getAssetURL("image.pageDownloading.macInstallImage")
const macChromeDownloadImage = getAssetURL("image.pageDownloading.macChromeDownloadImage")
const macSafariDownloadImage = getAssetURL("image.pageDownloading.macSafariDownloadImage")
const connectImage = getAssetURL("image.pageDownloading.connectImage")
const watchImage = getAssetURL("image.pageDownloading.watchImage")

const platformName = {
  linux: "Linux",
  darwin: "macOS",
  win32: "Windows",
}

const isMacM1 = () => {
  try {
    const w = document.createElement("canvas").getContext("webgl")
    const d = w.getExtension("WEBGL_debug_renderer_info")
    const g = (d && w.getParameter(d.UNMASKED_RENDERER_WEBGL)) || ""

    return Boolean(g.match(/Apple/)) && !Boolean(g.match(/Apple GPU/))
  } catch (e) {
    return false
  }
}

function getLink(payload, platform, arch) {
  if (payload) {
    if (platform === "darwin") {
      let version = get(payload, "version")
      if (version.startsWith("v")) version = version.substring(1)
      return formatterHelper.basic(consts.endpoints.downloadDarwinBuild, { platform, arch, fileArch: arch, version })
    } else if (platform === "win32") {
      let version = get(payload, "version")
      if (version.startsWith("v")) version = version.substring(1)
      return formatterHelper.basic(consts.endpoints.downloadWindowsBuild, { platform, arch, version })
    }
    return get(payload, "url")
  }
}

@connect(
  state => {
    const navigator = selectorNavigator(state)
    const download = get(state, "download.payload", null)

    navigator.isMacM1 = isMacM1()

    return {
      navigator,
      download,
    }
  },
  dispatch => ({
    downloadLink: downloadLink(dispatch),
  })
)
export default class PageDownloading extends Component {
  static defaultProps = {
    navigator: {},
    isPending: true,
    location: {
      query: "",
    },
  }

  static propTypes = {
    download: PropTypes.object,
    downloadLink: PropTypes.func,
    detectDevice: PropTypes.func,
    navigator: PropTypes.object,
    location: PropTypes.object,
  }

  state = {
    redirected: false,
    navigator: this.props.navigator,
    link: "",
    donwloadEnabled: !this.isDeprecatedOsx(this.props.navigator),
  }

  static getMeta() {
    return {
      appLinks: {
        iosNativeUri: consts.externalLink.adjustDownload,
        androidNativeUri: consts.externalLink.adjustDownload,
        webUri: consts.externalLink.landing + consts.routes.download,
      },
      og: {
        title: i18n.pageTitle.public,
        type: "website",
        image: getAssetURL("image.pageHome.ogImage"),
        imageAlt: "Logo Molotov.tv",
        url: `${consts.externalLink.landing}${routes.download}`,
        imageHeight: 400,
        imageWidth: 588,
        description: "Téléchargez Molotov.tv",
      },
    }
  }

  getWindowsSteps() {
    return [
      <DownloadStep
        img={windowsDownloadImage}
        number="1"
        key="1"
        title="Telecharger"
        instructions="Enregistrez le fichier d'installation sur votre ordinateur."
      />,
      <DownloadStep img={windowsInstallImage} number="2" key="2" title="Installer" instructions="Double-cliquez sur le fichier d’installation." />,
    ]
  }

  getMacSteps() {
    const { isSafari } = this.state.navigator

    let img = macChromeDownloadImage
    let instructions = "Cliquez sur le fichier Molotov qui apparait en bas à gauche de votre écran."

    if (isSafari) {
      img = macSafariDownloadImage
      instructions = "Cliquez sur le fichier Molotov dans vos téléchargements."
    }

    return [
      <DownloadStep img={img} number="1" key="1" title="Telecharger" instructions={instructions} />,
      <DownloadStep
        img={macInstallImage}
        number="2"
        key="2"
        title="Installer"
        instructions="Faites glisser molotov dans votre dossier d’Applications."
      />,
    ]
  }

  getDownloadLink = (selectedPlatform, selectedArch) => {
    let platform

    if (selectedPlatform === "linux") {
      platform = "isLinux"
    } else if (selectedPlatform === "darwin") {
      platform = selectedArch === "arm64" ? "isMacM1" : "isMac"
    } else {
      platform = selectedArch === "x64" ? "isWindows64" : "isWindows"
    }

    this.props.downloadLink(selectedPlatform, selectedArch)

    const navigator = {
      isMac: false,
      isMacM1: false,
      isWindows64: false,
      isLinux: false,
      isWindows: false,
      [platform]: true,
    }

    this.setState({
      navigator: navigator,
      donwloadEnabled: true,
    })
  }

  getPlatform = () => {
    const { isMac, isWindows, isWindows64, isLinux, isMacM1 } = this.state.navigator
    let platform = undefined
    if (isLinux) platform = "linux"
    if (isMac || isMacM1) platform = "darwin"
    if (isWindows || isWindows64) platform = "win32"

    return platform
  }

  getCPUArch = () => {
    const { isMac, isWindows, isWindows64, isMacM1 } = this.state.navigator
    let arch = undefined
    if (isMacM1) arch = "arm64"
    else if (isMac) arch = "x64"
    else if (isWindows64) arch = "x64"
    else if (isWindows) arch = "ia32"

    return arch
  }

  // All versions for OSX from Mavericks (10.9) and below
  // will be deprecated
  isDeprecatedOsx(navigator) {
    if (!navigator.isMac || !navigator.osxVersion) {
      return false
    }

    const versions = navigator.osxVersion.split("_")

    return versions[0] <= 10 && versions[1] <= 11
  }

  getDownloadLinksContent = () => {
    return (
      <>
        <a href={this.state.link} download={true} className={styles.link} id="download-link" />
        <p>
          {" "}
          Molotov est disponible uniquement sur Windows 10 et 11 {" ("}
          <span onClick={() => this.getDownloadLink("win32", "x64")} className={styles.link}>
            64 bits{" "}
          </span>
          ou{" "}
          <span onClick={() => this.getDownloadLink("win32", "ia32")} className={styles.link}>
            {" "}
            32 bits
          </span>
          {"), "}
          <span onClick={() => this.getDownloadLink("darwin", "x64")} className={styles.link}>
            Mac Intel
          </span>
          {", "}{" "}
          <span onClick={() => this.getDownloadLink("darwin", "arm64")} className={styles.link}>
            {" "}
            Mac M1
          </span>{" "}
          -{" "}
          <span onClick={() => this.getDownloadLink("darwin", "arm64")} className={styles.link}>
            {" "}
            M2
          </span>{" "}
          -{" "}
          <span onClick={() => this.getDownloadLink("darwin", "arm64")} className={styles.link}>
            {" "}
            M3
          </span>{" "}
          et{" "}
          <span onClick={() => this.getDownloadLink("linux")} className={styles.link}>
            {" "}
            Ubuntu{" "}
          </span>
        </p>
      </>
    )
  }

  getSubtitle = () => {
    if (this.isDeprecatedOsx(this.state.navigator)) {
      return this.getDownloadLinksContent()
    }
    return (
      <div>
        <p style={{ marginBottom: "20px" }}>Merci !</p>
        Si votre téléchargement ne démarre pas,{" "}
        <span onClick={() => this.getDownloadLink(this.getPlatform())} className={styles.link}>
          cliquez ici{" "}
        </span>
        pour le lancer immédiatement.
        {this.getDownloadLinksContent()}
      </div>
    )
  }

  componentDidMount() {
    const { isIpad, isAndroid, isIphone, isMac, isMacM1, isWindows, isLinux } = this.state.navigator

    if (isIpad || isIphone || isAndroid) {
      const { location } = this.props
      const url = routeHelpers.getDownloadURL(get(location, "query.campaign", "marketing_site"), get(location, "query.adgroup"))
      this.setState({ redirected: true })
      window.location.href = url
    }
    if (!this.state.link && (isMac || isWindows || isLinux || isMacM1)) {
      let platform = this.getPlatform()
      let arch = this.getCPUArch()
      this.props.downloadLink(platform, arch)
    }
    updateMeta(PageDownloading.getMeta())
  }

  componentDidUpdate(prevProps) {
    if (this.props.download !== prevProps.download) {
      this.setState({
        link: getLink(this.props.download, this.getPlatform(), this.getCPUArch()),
      })
    }

    const el = document.getElementById("download-link")
    if (el && el.click && this.state.donwloadEnabled) {
      setTimeout(() => el.click(), 1000)
      this.setState({
        donwloadEnabled: false,
      })
    }
  }

  componentWillUnmount() {
    restoreDefaultMeta()
  }

  render() {
    const { location } = this.props
    const { redirected, navigator } = this.state

    if (redirected) {
      return (
        <div className={styles.root}>
          <p className={styles.thanks}>Merci d&#39;avoir téléchargé Molotov.</p>
        </div>
      )
    }

    if (!this.state.link) {
      return <Loader centerInParent={false} />
    }

    let headerMessage = "Téléchargement de Molotov pour " + platformName[this.getPlatform()]

    if (location && location.query && location.query.newAccount === "true") {
      headerMessage = "Votre compte a bien été créé"
    }

    const deprecatedOsx = this.isDeprecatedOsx(navigator)

    return (
      <div className={classNames(styles.root, { [styles.rootDeprecatedOsx]: deprecatedOsx })} data-test="page-download">
        <ContentHeader title={<span>{headerMessage}</span>} subTitle={this.getSubtitle()} headerSubtitleStyle={styles.subtitle} />
        {!deprecatedOsx && (
          <div className={classNames(ContentHeader.above, ContentBox.mt100)}>
            <h2 className={ContentBox.hasTextCentered} />
            <div>
              <ContentBox className={styles.box}>
                <div className={styles.steps}>
                  {navigator.isMac || navigator.isMacM1 ? this.getMacSteps() : this.getWindowsSteps()}
                  <DownloadStep img={connectImage} number="3" title="Connecter" instructions="Connectez-vous ou créez un compte." />
                  <DownloadStep img={watchImage} number="4" title="Regarder" instructions="Profitez à fond de Molotov !" />
                </div>
              </ContentBox>
              <p className={styles.help}>
                {i18n.downloadPage.support_message}
                <a className={styles.helpLink} href={consts.externalLink.supportForm} target="_blank">
                  {i18n.downloadPage.customer_service}
                </a>
              </p>
            </div>
          </div>
        )}
        {deprecatedOsx && (
          <div className={styles.deprecatedOsxWarning}>
            Depuis le 29 novembre 2021, nous ne sommes plus en capacité de supporter votre système d'exploitation (OS X El Capitain ou inférieur).
            Nous vous recommandons de mettre à jour votre système d'exploitation à une version plus récente de macOS si vous le pouvez.
          </div>
        )}
      </div>
    )
  }
}

import { DELETE_NOTIFICATION } from "consts/actions"

export function deleteNotification(notificationId) {
  return {
    type: DELETE_NOTIFICATION,
    payload: {
      notificationId,
    },
  }
}

import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import Logo from "components/Logo/Logo"

import styles from "./index.css"

const ProductOpenEurope = ({ anchor }) => {
  return (
    <div className={styles.root}>
      <Logo height={70} mixedColor={true} color="#000" />
      <h3>
        La télé partout en Europe<sup>(1)</sup>
      </h3>
      <p>
        Abonnez-vous à <b>Molotov Plus</b>. <br className={styles.hideMobile} />
        Accédez à 50 chaînes de la télévision française (dont la TNT) <br className={styles.hideMobile} />
        en direct et en replay. <br />
        Disponible sur tous vos écrans (téléviseur, ordinateur, tablette et smartphone) <br className={styles.hideMobile} />
        <b>et depuis toute l'Europe !</b>
        <br />
        <br />
        Offre sans engagement, résiliable à tout moment.
      </p>
      <Link to={anchor}>DECOUVRIR</Link>
      <p className={styles.small}>(1) Dans les pays de l’Union Européenne, Islande, Norvège et Liechenstein.</p>
    </div>
  )
}

ProductOpenEurope.propTypes = {
  anchor: PropTypes.string.isRequired,
}

export default ProductOpenEurope

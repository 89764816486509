import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconClose = ({ customStyle = null }) => (
  <svg viewBox="0 0 24 24" className={classNames(customStyle, styles.fill)}>
    <path
      fillRule="evenodd"
      d="M12 10.586l6.293-6.293a1 1 0 0 1 1.414 1.414L13.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 1 1-1.414-1.414L10.586 12 4.293 5.707a1 1 0 0 1 1.414-1.414L12 10.586z"
    />
  </svg>
)

IconClose.propTypes = {
  customStyle: PropTypes.string,
}

export default IconClose

import React, { Component } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import classNames from "classnames"

import IconLink from "components/Icon/IconLink/IconLink"

import styles from "./nav.css"

export default class NavItem extends Component {
  static propTypes = {
    showMobileNav: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    link: PropTypes.string,
    rootStyle: PropTypes.string,
    onClick: PropTypes.func,
    openExternal: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }

  static defaultProps = {
    isPageNav: false,
    title: "My NavItem",
    link: "",
    rootStyle: "",
    onClick: null,
  }

  // Have to do this by hand
  // NavLink does not handle query params
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/NavLink.md#isactive-func
  handleActiveState = (match, title) => {
    if (title === "Créer un compte" && this.props.location.search === "?signup=true") {
      return true
    }
    if (title === "Me connecter" && this.props.location.search === "?signup=true") {
      return false
    }
    return !!match
  }

  render() {
    const { showMobileNav, title, link, rootStyle, onClick, openExternal, location } = this.props

    const navLinkProps = {}

    if (showMobileNav) {
      // pass a custom location with the last url
      // when we're on the nav page to have the
      // correct active link
      navLinkProps.location = {
        pathname: location.pathname,
      }
    }

    if (openExternal) {
      const externalLinkDescriptor = `Read more about ${title}`

      return (
        <a href={link} className={classNames(styles.root, rootStyle)} onClick={onClick} target="_blank" aria-label={externalLinkDescriptor}>
          {title}
          <IconLink customStyle={styles.iconLink} />
        </a>
      )
    }

    return (
      <NavLink
        isActive={match => this.handleActiveState(match, title)}
        exact
        to={link}
        className={classNames(styles.root, rootStyle)}
        activeClassName={styles.active}
        onClick={onClick}
        {...navLinkProps}
      >
        {title}
      </NavLink>
    )
  }
}

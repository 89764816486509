import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const Lock = ({ customStyle = null }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classNames(customStyle, styles.lock)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11V7a5 5 0 0 1 10 0v4h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1zm2 0h6V7a3 3 0 1 0-6 0v4zm3 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
      fill="#1B1C20"
    />
  </svg>
)

Lock.propTypes = {
  customStyle: PropTypes.string,
}

export default Lock

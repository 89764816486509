import { createSelector } from "reselect"
import { get } from "lodash"

const selectProductBookmarkConstraints = state => state.productBookmarkConstraints

export default productId =>
  createSelector([selectProductBookmarkConstraints], productBookmarkConstraints => {
    let constraints = productBookmarkConstraints && productBookmarkConstraints[productId] ? productBookmarkConstraints[productId] : null

    if (constraints) {
      constraints = !!get(constraints, "data.items", []).length
    }

    return constraints
  })

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Notifications from "components/Notifications/Notifications"

import styles from "./borderBox.css"

const BorderBox = ({ className, children, navVisible, invisibleMode }) => (
  <div className={classNames(className, styles.root, { [styles.withNav]: navVisible, [styles.invisible]: invisibleMode })}>
    <Notifications />
    {children}
  </div>
)

BorderBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  navVisible: PropTypes.bool,
  invisibleMode: PropTypes.bool,
  className: PropTypes.string,
}

export default BorderBox

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Button from "components/Button/Button"
import Toggle from "components/Toggle/Toggle"

import { hideCookieBanner, setPrivacySettings } from "actions/privacy"
import { open as openFlash } from "actions/flash"

import flashStatus from "containers/Flash/status"

import { clearCookies } from "managers/privacy"
import TrackingSegment from "managers/segmentManager"

import styles from "./cookieSettings.css"
import consts from "consts"
import i18n from "consts/i18n"

@connect(state => ({
  privacy: state.privacy,
}))
export default class CookieSettings extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    privacy: PropTypes.object,
    render: PropTypes.bool,
  }

  onValidate = () => {
    this.props.dispatch(openFlash(i18n.legal.cookies.success, null, flashStatus.success))
    this.props.dispatch(hideCookieBanner())
    this.props.onValidate()

    if (
      (this.currentSettings[consts.privacy.acceptTracking] !== this.props.privacy[consts.privacy.acceptTracking] &&
        !this.props.privacy[consts.privacy.acceptTracking]) ||
      (this.currentSettings[consts.privacy.acceptAds] !== this.props.privacy[consts.privacy.acceptAds] &&
        !this.props.privacy[consts.privacy.acceptAds])
    ) {
      clearCookies()
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  setSettings = newSettings => {
    this.props.dispatch(
      setPrivacySettings({
        ...this.props.privacy,
        ...newSettings,
      })
    )
  }

  toggleTracking = () => {
    this.setSettings({ [consts.privacy.acceptTracking]: !this.props.privacy[consts.privacy.acceptTracking] })
  }

  toggleAds = () => {
    this.setSettings({ [consts.privacy.acceptAds]: !this.props.privacy[consts.privacy.acceptAds] })
  }

  componentDidMount() {
    TrackingSegment.trackPage("optins", {
      optin_name: "cookies",
    })
    this.currentSettings = {
      [consts.privacy.acceptTracking]: this.props.privacy[consts.privacy.acceptTracking],
      [consts.privacy.acceptAds]: this.props.privacy[consts.privacy.acceptAds],
    }
  }

  render() {
    const { privacy, render } = this.props

    return (
      <>
        <div className={styles.title}>{i18n.cookieSettings.title}</div>
        <div className={styles.description}>{i18n.cookieSettings.subtitle}</div>
        <div className={styles.section}>
          <div className={styles.textWrapper}>
            <div className={styles.subtitle}>{i18n.cookieSettings.section1.title}</div>
            <div className={styles.detail}>{i18n.cookieSettings.section1.description}</div>
          </div>
          <div className={styles.toggleWrapper}>
            <Toggle customStyle={styles.toggle} disabled={true} activated={true} />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.textWrapper}>
            <div className={styles.subtitle}>{i18n.cookieSettings.section2.title}</div>
            <div className={styles.detail}>{i18n.cookieSettings.section2.description}</div>
          </div>
          <div className={styles.toggleWrapper}>
            <Toggle activated={render ? privacy[consts.privacy.acceptTracking] : false} customStyle={styles.toggle} onClick={this.toggleTracking} />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.textWrapper}>
            <div className={styles.subtitle}>{i18n.cookieSettings.section3.title}</div>
            <div className={styles.detail}>{i18n.cookieSettings.section3.description}</div>
          </div>
          <div className={styles.toggleWrapper}>
            <Toggle activated={render ? privacy[consts.privacy.acceptAds] : false} customStyle={styles.toggle} onClick={this.toggleAds} />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button color={Button.colors.yellowFull} customStyle={styles.button} value={i18n.validate} onClick={this.onValidate} />
        </div>
      </>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"

import Icon, { IconTypes } from "components/Icon/Icon"
import PageResetPassword from "containers/PageResetPassword"

import styles from "./index.css"

const ModalResetPassword = ({ close }) => (
  <div>
    <Icon type={IconTypes.close} rootStyle={styles.close} iconStyle={styles.closeIcon} onClick={close} />
    <PageResetPassword customStyle={styles.pageResetPassword} />
  </div>
)

ModalResetPassword.propTypes = {
  close: PropTypes.func.isRequired,
}

const ModalResetPasswordCustomStyle = styles.modal

export default ModalResetPassword
export { ModalResetPasswordCustomStyle }

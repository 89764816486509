import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./menuItem.css"
import consts from "consts"

const MenuItem = ({ children, rootStyle, onClick }) => (
  <li className={classnames(styles.root, rootStyle)} onClick={onClick}>
    {children}
  </li>
)

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rootStyle: PropTypes.string,
  onClick: PropTypes.func,
}

MenuItem.defaultProps = {
  rootStyle: "",
  onClick: consts.NoOp,
}

export default MenuItem

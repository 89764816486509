import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Interstitial from "./Interstitial"
import PasswordForm from "./PasswordForm"
import Loader from "components/Loader/Loader"

// Selectors
import { selectPasswordForm, selectInterstitial } from "selectors/mtvPay"
import { selectDspPaymentForProduct } from "selectors/dspPayment"

@connect((state, ownProps) => ({
  interstitial: selectInterstitial(state, ownProps.equivalenceCode),
  passwordForm: selectPasswordForm(state, ownProps.equivalenceCode),
  subscriptionSuccess: selectDspPaymentForProduct(state, ownProps.equivalenceCode).subscriptionSuccess,
}))
export default class Dsp extends Component {
  static propTypes = {
    equivalenceCode: PropTypes.string,
    footer: PropTypes.object,
    onCancel: PropTypes.func,
    passwordForm: PropTypes.object,
    paymentPending: PropTypes.bool,
    paymentTracker: PropTypes.func,
    subscriptionSuccess: PropTypes.bool,
    onSuccess: PropTypes.func,
    interstitial: PropTypes.object,
    isFramelessMode: PropTypes.bool,
    dsp: PropTypes.string,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.subscriptionSuccess && this.props.subscriptionSuccess) {
      this.props.onSuccess()
    }
  }

  render() {
    const { equivalenceCode, footer, onCancel, passwordForm, paymentPending, interstitial, isFramelessMode, dsp } = this.props

    this.trackerContext = "password_confirmation"
    if (!passwordForm) {
      return <Loader />
    } else {
      if (interstitial) {
        return (
          <Interstitial
            equivalenceCode={equivalenceCode}
            footer={footer}
            interstitial={interstitial}
            isFramelessMode={isFramelessMode}
            tracker={this.props.paymentTracker}
            trackerContext={this.trackerContext}
            dsp={dsp}
          />
        )
      }
      return (
        <PasswordForm
          onCancel={onCancel}
          equivalenceCode={equivalenceCode}
          footer={footer}
          passwordForm={passwordForm}
          paymentPending={paymentPending}
          tracker={this.props.paymentTracker}
        />
      )
    }
  }
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import Separator from "components/Separator/Separator"
import Input from "components/Input/Input"
import InputSubmit from "components/InputSubmit/InputSubmit"
import Button from "components/Button/Button"
import Alert from "components/Alert/Alert"

import { check as checkPassword, resetPasswordForm } from "actions/password"

import selectSubscriptions from "selectors/subscriptions"
import { resetSubscriptionsError } from "actions/subscriptions"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect(state => ({
  passwordForm: state.forms.password,
  subscriptions: selectSubscriptions(state),
}))
export default class ProductPassword extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    footer: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    passwordForm: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    subscriptions: PropTypes.object,
    isSubscribe: PropTypes.bool,
    isReSubscribing: PropTypes.bool,
    id: PropTypes.string,
    dispatch: PropTypes.func,
  }

  static defaultProps = {
    passwordForm: {},
    customStyle: "",
    subscriptions: {},
    isSubscribe: false,
    isReSubscribing: false,
    id: "",
  }

  state = {
    password: "",
  }

  cancel = () => {
    const { onCancel, id } = this.props

    this.props.dispatch(resetPasswordForm())
    this.props.dispatch(resetSubscriptionsError(id))
    onCancel()
  }

  onSubmit = event => {
    event.preventDefault()

    const { onSuccess } = this.props

    this.props.dispatch(checkPassword(this.state.password, onSuccess))
  }

  render() {
    const { customStyle, footer, passwordForm, subscriptions, isSubscribe, isReSubscribing, id } = this.props
    const error = passwordForm.error ? passwordForm.error : subscriptions.error && id === subscriptions.error.id ? subscriptions.error : null
    return (
      <form onSubmit={this.onSubmit} className={customStyle}>
        <h3 className={styles.title}>{i18n.productPassword.title}</h3>
        <Separator customStyle={styles.separator} />
        {error && (
          <Alert customStyle={styles.error}>
            <span>{error.message}</span>
          </Alert>
        )}
        {isSubscribe && !isReSubscribing && <p className={styles.text}>{i18n.productPassword.unsubscribe}</p>}
        {isSubscribe && isReSubscribing && (
          <div>
            <p className={styles.text}>{i18n.productPassword.retext}</p>
            <p className={styles.text}>{i18n.productPassword.resubText}</p>
          </div>
        )}
        {!isSubscribe && (
          <div>
            <p className={styles.text}>{i18n.productPassword.text}</p>
            <p className={styles.text}>{i18n.productPassword.subText}</p>
          </div>
        )}
        <Input
          type="password"
          autoFocus={true}
          rootStyle={styles.inputRoot}
          customStyle={styles.input}
          onChange={value => {
            this.setState({ password: value })
          }}
        />
        <Separator customStyle={styles.separator} />
        <div className={styles.buttons}>
          <InputSubmit
            customStyle={classNames(styles.rightButton, InputSubmit.types.yellowFull, {
              [styles.disabled]: subscriptions.pending || passwordForm.pending,
            })}
            onClick={this.onSubmit}
            value={i18n.validate}
          />
          <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={this.cancel} value={i18n.cancel} />
        </div>
        {footer}
      </form>
    )
  }
}

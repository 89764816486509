import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconMessage = ({ customStyle = null }) => (
  <svg viewBox="0 0 16 14" className={classNames(customStyle, styles.fill)}>
    <path
      fillRule="evenodd"
      d="M2.182 0h11.636c.602 0 1.117.212 1.543.635.427.423.64.934.64 1.532v8.666c0 .598-.213 1.11-.64 1.532-.425.423-.94.635-1.542.635H2.182c-.602 0-1.117-.212-1.543-.635-.427-.423-.64-.934-.64-1.532V2.167C0 1.57.213 1.057.64.635 1.064.212 1.58 0 2.18 0zm12.363 10.833V2.945l-6.09 4.84c-.13.107-.28.16-.455.16-.174 0-.326-.053-.455-.16l-6.09-4.84v7.888c0 .2.07.37.213.51.142.142.313.213.514.213h11.636c.2 0 .372-.07.514-.212.142-.14.213-.31.213-.51zm-.727-9.39H2.182c-.072 0-.152.015-.24.04L8 6.298l6.057-4.813c-.087-.026-.167-.04-.24-.04z"
    />
  </svg>
)

IconMessage.propTypes = {
  customStyle: PropTypes.string,
}

export default IconMessage

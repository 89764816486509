import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const Csa16 = ({ customStyle = null }) => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className={classNames(customStyle, styles.csa16)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.732 17.333h4.601v-2.666h-4.6c.668-6.738 6.353-12 13.267-12 7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333-6.914 0-12.599-5.262-13.268-12zM10 24h3.333V8H8.667v2.667H10V24zm10 .333c2.795 0 4.667-2.179 4.667-6 0-3.036-1.327-4.616-3.334-4.616-.671 0-1.284.111-2 .616L22.69 8h-3.16l-1.863 3.323c-1.179 2.439-2.132 4.537-2.132 7.344 0 3.68 1.558 5.666 4.465 5.666zM20.167 16c1.012 0 1.5 1.045 1.5 3s-.467 3-1.507 3c-1.04 0-1.493-1.089-1.493-3s.487-3 1.5-3z"
      fill="#fff"
    />
  </svg>
)

Csa16.propTypes = {
  customStyle: PropTypes.string,
}

export default Csa16

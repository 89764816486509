import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconCsa10 = ({ customStyle = null }) => (
  <svg viewBox="0 0 32 32" className={classNames(customStyle, styles.fill)}>
    <path d="M15.95 2C8.744 2 2.808 7.408 2 14.377h4.957v3.342H2.013C2.863 24.64 8.777 30 15.95 30 23.71 30 30 23.733 30 16 30 8.27 23.71 2 15.95 2zm-2.98 22.653H9.646V10.28H8V7.426h4.97v17.226zm7.246.215c-2.98 0-4.7-2.993-4.7-8.816 0-5.846 1.696-8.842 4.7-8.842 3.006 0 4.705 2.996 4.705 8.842 0 5.823-1.722 8.816-4.704 8.816zM20.22 9.62c-1.336 0-1.61 2.117-1.61 6.46 0 4.29.274 6.38 1.61 6.38 1.332 0 1.578-2.118 1.578-6.38 0-4.343-.246-6.46-1.578-6.46z" />
  </svg>
)

IconCsa10.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCsa10

import { createSelector } from "reselect"

const selectAccount = state => (state.session.data ? state.session.data.account : {})

export default createSelector([selectAccount], account => {
  return {
    abTesting: account.ab_testing || {},
    id: account.id || null,
    firstname: account.first_name || "",
    lastname: account.last_name || "",
    displayName: account.display_name || null,
    email: account.email || null,
    hashedEmail: account.hashed_email || null,
    gender: account.gender || null,
    birthday: account.birthday * 1000 || null,
    hasPassword: account.has_password,
    isPremium: account.is_premium,
    dsp: account.dsp,
  }
})

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconTablet = ({ customStyle = null }) => (
  <svg viewBox="-3 0 30 30" className={classNames(customStyle, styles.stroke)}>
    <defs>
      <path id="a" d="M0 0h23v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M22.5 26.5c0 1.65-1.35 3-3 3h-16c-1.65 0-3-1.35-3-3v-23c0-1.65 1.35-3 3-3h16c1.65 0 3 1.35 3 3v23z" strokeLinecap="round" />
      <path d="M19.5 23.5h-16v-19h16z" strokeLinecap="round" />
      <path d="M11.5 2c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5" />
      <path d="M12.5 26.5c0 .552-.448 1-1 1-.553 0-1-.448-1-1s.447-1 1-1c.552 0 1 .448 1 1z" strokeLinecap="round" />
    </g>
  </svg>
)

IconTablet.propTypes = {
  customStyle: PropTypes.string,
}

export default IconTablet

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconCheckmark = ({ customStyle = null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={classNames(customStyle, styles.fill)} width="96" height="96" viewBox="0 0 96 96">
    <path
      data-identifier="check-path"
      fill="none"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 53.5l17 17L78 28"
    />
  </svg>
)

IconCheckmark.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCheckmark

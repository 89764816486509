import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconChevron = ({ customStyle = null }) => (
  <svg className={classNames(customStyle, styles.fill)} width="22" height="9" viewBox="0 0 22 9">
    <polygon fillRule="evenodd" points="6.5 4.5 14.06 -6.5 15.5 -4.405 9.38 4.5 15.5 13.405 14.06 15.5" />
  </svg>
)

IconChevron.propTypes = {
  customStyle: PropTypes.string,
}

export default IconChevron

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import Input from "components/Input/Input"
import InputSubmit from "components/InputSubmit/InputSubmit"

import { reset as resetPassword, resetPasswordForm } from "actions/password"

import { selectQuery } from "selectors/location"
import selectAccount from "selectors/account"

import helperFormatter from "helpers/formatter"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect(
  (state, ownProps) => ({
    passwordForm: state.forms.password,
    query: selectQuery(ownProps),
    defaultEmail: selectAccount(state).email,
  }),
  dispatch => ({
    resetPassword: email => {
      dispatch(resetPassword(email))
    },
    dispatch,
  })
)
export default class PageResetPassword extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    defaultEmail: PropTypes.string,
    dispatch: PropTypes.func,
    passwordForm: PropTypes.object.isRequired,
    query: PropTypes.object,
    resetPassword: PropTypes.func.isRequired,
  }

  state = {
    email: null,
  }

  // Return first email value found by searching in:
  // - url query parameter "email"
  // and if not found, look in:
  // - redux store (in account state slice)
  getExtractedEmail = () => {
    return this.props.query.email || this.props.defaultEmail
  }

  // Return first email value found by searching in:
  // - this.getExtractedEmail()
  // and if not found, look in:
  // - this.state.email
  getBestEmail = () => {
    return this.getExtractedEmail() || this.state.email
  }

  onSubmit = ev => {
    ev.preventDefault()
    this.props.resetPassword(this.getBestEmail())
  }

  componentDidMount() {
    this.props.dispatch(resetPasswordForm())
  }

  render() {
    const { customStyle, passwordForm } = this.props

    const extractedEmail = this.getExtractedEmail()
    const bestEmail = this.getBestEmail()

    return (
      <div className={classNames(styles.root, customStyle)}>
        <div className={styles.content}>
          <h3 className={styles.title}>{i18n.resetPasswordPage.title}</h3>
          {!passwordForm.success && (
            <form onSubmit={this.onSubmit}>
              {extractedEmail ? (
                <p className={styles.subText}>
                  {helperFormatter.styled(i18n.resetPasswordPage.subText, [{ title: extractedEmail, bold: true, color: "#000" }])}
                </p>
              ) : (
                <>
                  <p className={styles.subText}>{i18n.resetPasswordPage.subTextInput}</p>
                  <Input
                    rootStyle={styles.inputRoot}
                    customStyle={styles.input}
                    placeholder={i18n.email}
                    errorMessage={passwordForm.error ? passwordForm.error.message || i18n.resetPasswordPage.error : null}
                    onChange={value => {
                      this.setState({ email: value })
                    }}
                    value={this.state.email || ""}
                  />
                </>
              )}
              <p className={styles.subText}>{i18n.resetPasswordPage.reconnectText}</p>
              <p className={styles.description}>{i18n.resetPasswordPage.description}</p>

              <InputSubmit
                type="submit"
                customStyle={classNames(InputSubmit.types.yellowFull, {
                  [styles.button]: true,
                  [styles.disabled]: passwordForm.pending,
                })}
                value={i18n.send}
              />
            </form>
          )}
          {passwordForm.success && (
            <div className={styles.successContent}>
              <p>{i18n.resetPasswordPage.successText}</p>
              <div className={styles.email}>{bestEmail}</div>
              <p className={styles.successText}>{i18n.resetPasswordPage.successDescription}</p>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div>{passwordForm.success ? i18n.resetPasswordPage.successHelp : i18n.resetPasswordPage.help}</div>
        </div>
      </div>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import types from "./types"
import styles from "./control.css"

const Control = ({ type, action, isHtmlContent }) => {
  return (
    <div
      className={classNames({
        [styles.controlNext]: type === types.next,
        [styles.controlPrev]: type === types.prev,
        [styles.isHtmlContent]: isHtmlContent,
      })}
      onClick={action}
    >
      <div
        className={classNames({
          [styles.next]: type === types.next,
          [styles.prev]: type === types.prev,
          [styles.arrowIsHtmlContent]: isHtmlContent,
        })}
      />
    </div>
  )
}

Control.propTypes = {
  type: PropTypes.string,
  action: PropTypes.func,
  isHtmlContent: PropTypes.bool,
}

export default Control

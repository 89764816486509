import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

// Components
import Loader from "components/Loader/Loader"

import Interstitial from "./Interstitial"
import PaymentForm from "./PaymentForm"
import PaymentFormDesktop from "./PaymentFormDesktop"
import PasswordForm from "./PasswordForm"

// Selectors
import {
  selectMtvPayPaymentForProduct,
  selectInterstitial,
  selectPasswordForm,
  selectPasswordFormError,
  selectPaymentForm,
  selectPaymentPending,
} from "selectors/mtvPay"

@connect((state, ownProps) => ({
  interstitial: selectInterstitial(state, ownProps.equivalenceCode),
  passwordForm: selectPasswordForm(state, ownProps.equivalenceCode),
  passwordError: selectPasswordFormError(state, ownProps.equivalenceCode),
  paymentForm: selectPaymentForm(state, ownProps.equivalenceCode),
  cardFormData: state.mtvPay.cardForm,
  paymentPending: selectPaymentPending(state, ownProps.equivalenceCode),
  subscriptionSuccess: selectMtvPayPaymentForProduct(state, ownProps.equivalenceCode).subscriptionSuccess,
  isDesktop: state.appSettings.device === "desktop",
}))
export default class MolotovPay extends Component {
  static propTypes = {
    cardFormData: PropTypes.object,
    equivalenceCode: PropTypes.string,
    footer: PropTypes.object,
    interstitial: PropTypes.object,
    isFramelessMode: PropTypes.bool,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    passwordError: PropTypes.object,
    passwordForm: PropTypes.object,
    paymentForm: PropTypes.object,
    paymentPending: PropTypes.bool,
    paymentTracker: PropTypes.func,
    subscriptionSuccess: PropTypes.bool,
    currentProduct: PropTypes.object,
    isReSubscribing: PropTypes.bool,
    isDesktop: PropTypes.bool,
    fromOptionPage: PropTypes.bool,
  }

  passwordFormShown = false

  componentDidUpdate(prevProps) {
    if (!prevProps.subscriptionSuccess && this.props.subscriptionSuccess) {
      this.props.onSuccess()
    }
  }

  render() {
    const {
      cardFormData,
      equivalenceCode,
      footer,
      interstitial,
      isFramelessMode,
      onCancel,
      passwordError,
      passwordForm,
      paymentForm,
      paymentPending,
      isReSubscribing,
      isDesktop,
      fromOptionPage,
    } = this.props

    const paypalInfos = {
      amount: this.props.currentProduct.discounted_price,
      currency: this.props.currentProduct.currency,
      ratePlanId: this.props.currentProduct.rate_plan_id,
      equivalenceCode,
      isTBCC: this.props.currentProduct?.discount?.ratio === 1,
    }

    if (!paymentForm && !passwordForm && !interstitial) {
      return <Loader />
    } else {
      if (interstitial) {
        return (
          <Interstitial
            equivalenceCode={equivalenceCode}
            footer={footer}
            interstitial={interstitial}
            isFramelessMode={isFramelessMode}
            tracker={this.props.paymentTracker}
            trackerContext={this.trackerContext}
          />
        )
      } else if (paymentForm) {
        this.trackerContext = "payment"
        if (isDesktop) {
          return (
            <PaymentFormDesktop
              amount={this.props.currentProduct.price}
              formData={cardFormData}
              equivalenceCode={equivalenceCode}
              footer={footer}
              onCancel={onCancel}
              passwordError={passwordError}
              paymentForm={paymentForm}
              paymentPending={paymentPending}
              tracker={this.props.paymentTracker}
              paypalInfos={{ ...paypalInfos, passwordFormShown: this.passwordFormShown }}
              onSuccess={this.props.onSuccess}
            />
          )
        } else {
          return (
            <PaymentForm
              formData={cardFormData}
              discount={this.props.currentProduct.discount}
              discountedPrice={this.props.currentProduct.discounted_price}
              equivalenceCode={equivalenceCode}
              footer={footer}
              onCancel={onCancel}
              paymentForm={paymentForm}
              paymentPending={paymentPending}
              tracker={this.props.paymentTracker}
              paypalInfos={{ ...paypalInfos, passwordFormShown: this.passwordFormShown }}
              onSuccess={this.props.onSuccess}
              reinsurance={this.props.currentProduct.reinsurance}
              productName={this.props.currentProduct.name}
              fromOptionPage={fromOptionPage}
            />
          )
        }
      } else if (passwordForm) {
        this.trackerContext = "password_confirmation"
        this.passwordFormShown = true
        return (
          <PasswordForm
            discount={this.props.currentProduct.discount}
            discountedPrice={this.props.currentProduct.discounted_price}
            onCancel={onCancel}
            equivalenceCode={equivalenceCode}
            footer={footer}
            passwordForm={passwordForm}
            paymentPending={paymentPending}
            tracker={this.props.paymentTracker}
            paypalInfos={isReSubscribing ? {} : paypalInfos}
            onSuccess={this.props.onSuccess}
          />
        )
      } else {
        return null
      }
    }
  }
}

import { get } from "lodash"

import { DSP_PAYMENT_PENDING, DSP_PAYMENT_SUCCESS, DSP_PAYMENT_ERROR, SET_DSP_PASSWORD_ERROR, SET_DSP_PAYMENT_FAILED } from "consts/actions"

import { executeApiActions } from "actions/api"

import PixelCodeManager from "managers/pixelCodeManager"

import consts from "consts"

export function postDspPayment(action, body, equivalenceCode) {
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: action.url,
      method: action.method,
      body,
    },
    onStart() {
      return {
        type: DSP_PAYMENT_PENDING,
        equivalenceCode,
      }
    },
    onSuccess(payload, meta, dispatch, getState) {
      if (payload.execute_actions) {
        dispatch(executeApiActions(payload.execute_actions, { productPayment: true }))
      }

      // non null value needed
      PixelCodeManager.trackEvent("Subscribe", { value: "3.99", currency: "EUR", predicted_ltv: "19.99" })
      window.gtag &&
        window.gtag("event", "conversion", {
          send_to: consts.privacy.gtagSubScribe,
          transaction_id: "",
          user_id: getState().account.id,
        })

      return {
        type: DSP_PAYMENT_SUCCESS,
        equivalenceCode,
      }
    },
    onError(payload, meta, dispatch) {
      if (payload.error.execute_actions) {
        dispatch(executeApiActions(payload.error.execute_actions, { productPayment: true }))
      } else if (Number(get(payload, "error.error_code")) === consts.api.errorCodes.INVALID_CREDENTIALS) {
        dispatch({
          type: SET_DSP_PASSWORD_ERROR,
          equivalenceCode,
          payload: get(payload, "error.user_message"),
        })
      } else if (Number(get(payload, "error.error_code")) === consts.api.errorCodes.SUB_DSP_FAILED) {
        dispatch({
          type: SET_DSP_PAYMENT_FAILED,
          equivalenceCode,
          payload: get(payload, "error.user_message"),
        })
      }
      return {
        type: DSP_PAYMENT_ERROR,
        equivalenceCode,
      }
    },
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconDesktop = ({ customStyle = null }) => (
  <svg viewBox="0 0 30 22" className={classNames(customStyle, styles.stroke)}>
    <defs>
      <path id="a" d="M0 0h30v22H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M29.5 17.5h-12v1h-5v-1H.5v2c0 1.105.895 2 2 2h25c1.105 0 2-.895 2-2v-2zm-1 0v-15c0-1.105-.895-2-2-2h-23c-1.105 0-2 .895-2 2v15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.5 15.5h-23v-13h23z" />
    </g>
  </svg>
)

IconDesktop.propTypes = {
  customStyle: PropTypes.string,
}

export default IconDesktop

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import Icon, { IconTypes } from "components/Icon/Icon"

import styles from "./index.css"

const ModalChannels = ({ close, details }) => {
  return (
    <>
      <Icon type={IconTypes.close} iconStyle={styles.closeIcon} onClick={close} />
      <div className={styles.root}>
        {details.map((detail, index) => (
          <div key={index}>
            <p className={styles.title}>{detail.title}</p>
            <p className={styles.description}>{detail.description}</p>
            <div className={classnames(styles.channels, { [styles.emptyChannels]: !detail.channels })}>
              {detail.channels &&
                detail.channels.map((channel, j) => <img key={j} src={channel.image} alt={channel.title} className={styles.channel} />)}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

ModalChannels.propTypes = {
  close: PropTypes.func,
  details: PropTypes.array,
}

ModalChannels.defaultProps = {
  close: () => {},
  details: [],
}

const ModalChannelsCustomStyle = styles.modalRoot
const ModalChannelsCustomRootStyle = styles.overlay

export { ModalChannelsCustomStyle, ModalChannelsCustomRootStyle }
export default ModalChannels

import { get } from "lodash"

import { DELETE_NOTIFICATION, ACTION_FEEDBACK, SUBMIT_SETTINGS_SUCCESS, SUBMIT_CODE_PIN_SUCCESS } from "consts/actions"

import { NotificationColors } from "components/Notifications/NotificationView"
import { IconTypes } from "components/Icon/Icon"

import consts from "consts"

const initialState = {
  idGenerator: 0,
  list: [],
}

const DOWNLOAD_NOTIFICATION_SUCCESS_DELAY = 2

function addNotification(state, properties) {
  if (!properties.title) {
    return state
  }

  /* No need to display a new notification if you have the same error title */
  if (get(state, "list.length", []) > 0) {
    const lastNotification = state.list[state.list.length - 1]
    if (lastNotification.title === properties.title) {
      return state
    }
  }

  // Use defaults values
  const newNotification = {
    autoCloseTimer: null,
    text: "",
    iconType: null,
    category: null,
    ...properties,
    _id: state.idGenerator,
  }

  return {
    idGenerator: state.idGenerator + 1,
    list: [...state.list.filter(notification => !notification.category || notification.category !== newNotification.category), newNotification],
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_NOTIFICATION:
      return {
        ...state,
        list: state.list.filter(notification => notification._id !== action.payload.notificationId),
      }

    case ACTION_FEEDBACK:
      return addNotification(state, {
        category: action.payload.subtype,
        iconType: {
          default: "lock",
        },
        title: action.payload.label,
      })

    case SUBMIT_SETTINGS_SUCCESS:
      return action.displayNotification
        ? addNotification(state, {
            title: consts.i18n.newNotifications.submitSettings,
            autoCloseTimer: DOWNLOAD_NOTIFICATION_SUCCESS_DELAY,
            color: NotificationColors.purple,
            closeBtn: false,
            iconType: IconTypes.parentalControl,
          })
        : state

    case SUBMIT_CODE_PIN_SUCCESS:
      return addNotification(state, {
        title: action.pinCodeAlreadyCreated ? consts.i18n.newNotifications.pinCodeEdited : consts.i18n.newNotifications.pinCodeCreated,
        autoCloseTimer: DOWNLOAD_NOTIFICATION_SUCCESS_DELAY,
        color: NotificationColors.purple,
        closeBtn: false,
        iconType: IconTypes.lock,
      })
    default:
      return state
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const Alert = ({ customStyle = null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={classNames(customStyle, styles.alert)}>
    <path fillRule="evenodd" d="M12 3a2 2 0 0 1 2 2v8a2 2 0 1 1-4 0V5a2 2 0 0 1 2-2zm0 18.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" />
  </svg>
)

Alert.propTypes = {
  customStyle: PropTypes.string,
}

export default Alert

import { createSelector } from "reselect"
import FormatterHelper from "helpers/formatter"

const _selectInteraction = state => state.interaction
const _selectButtons = subState => subState.buttons

export const selectInteraction = createSelector([_selectInteraction], interaction => {
  if (interaction) {
    const validColors = ["primary", "secondary", "borderless", "dark_grey"]

    interaction.buttons =
      interaction.buttons &&
      interaction.buttons.map(button => {
        button.actions = interaction.actions
        button.color = Array.isArray(button.styles) ? button.styles.find(s => validColors.indexOf(s) > -1) : ""

        return button
      })
  }

  return interaction
})

export const selectButtons = createSelector([_selectButtons], buttons => {
  const validColors = ["button_primary_outer_top", "button_primary", "button_secondary", "button_borderless", "button_link", "button_dark_grey"]
  let selectedButtons

  selectedButtons = Array.isArray(buttons)
    ? buttons.map(button => {
        return {
          ...button,
          color: validColors.includes(button.style) ? button.style : "",
          title: button.title_formatter ? FormatterHelper.styled(button.title_formatter.format, button.title_formatter.parts) : button.title,
          outerTitle: button.outer_title_formatter
            ? FormatterHelper.styled(button.outer_title_formatter.format, button.outer_title_formatter.parts)
            : "",
        }
      })
    : []
  return selectedButtons
})

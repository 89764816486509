import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconDownload = ({ customStyle = null }) => (
  <svg viewBox="0 -1 14 14" className={classNames(customStyle, styles.fill)}>
    <g fillRule="evenodd">
      <path d="M12.5 2H14v9h-2V4h-2V2h2.5zM2 11V4H0v9h14v-2H2zM0 2h4v2H0V2z" />
      <path d="M6 6V0h2v6h1.01c.546 0 .724.355.39.8L7.6 9.2c-.33.442-.866.445-1.2 0L4.6 6.8c-.33-.442-.15-.8.39-.8H6z" />
    </g>
  </svg>
)

IconDownload.propTypes = {
  customStyle: PropTypes.string,
}

export default IconDownload

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconChecked = ({ customStyle = null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" className={classNames(customStyle, styles.fill)}>
    <g fill="none" fillRule="evenodd">
      <circle cx="60" cy="60" r="58" stroke="#7B7F8D" strokeWidth="4" />
      <path
        fill="#67C345"
        d="M52.272 76.168l35.67-35.67a4.383 4.383 0 1 1 6.2 6.198L54.926 85.911a4.383 4.383 0 0 1-6.808-.76 4.42 4.42 0 0 1-.408-.47L29.107 59.994a4.383 4.383 0 0 1 7-5.276l16.165 21.451z"
      />
    </g>
  </svg>
)

IconChecked.propTypes = {
  customStyle: PropTypes.string,
}

export default IconChecked

/* eslint-disable react/no-multi-comp */
import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./textInput.css"

const TextInput = ({
  autoComplete = "off",
  value,
  type,
  label,
  errorMessage = "",
  inputRef = null,
  inputStyles,
  labelStyles,
  onChange,
  rootStyle,
  onKeyPress,
  dataRecurly,
  error = false,
  onBlur = () => {},
  onFocus = () => {},
  focus = false,
}) => {
  const [showPassword] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const handleFocus = () => {
    setShowErrorMessage(false)
    onFocus()
  }

  const handleBlur = () => {
    setShowErrorMessage(true)
    onBlur()
  }

  const getInputType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password"
    }
    if (type === "date") {
      return "text"
    }
    return type
  }

  return (
    <div
      className={classNames(styles.inputWrapper, rootStyle, {
        [styles.error]: error,
      })}
    >
      <input
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        type={getInputType()}
        className={classNames(styles.input, inputStyles)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={onKeyPress}
        autoFocus={focus}
        data-recurly={dataRecurly}
        ref={inputRef}
      />
      <div
        className={classNames(styles.inputLabel, labelStyles, {
          [styles.active]: !!value.length,
        })}
      >
        {label}
      </div>
      {showErrorMessage && error && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  dataRecurly: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  getValue: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  inputStyles: PropTypes.string,
  labelStyles: PropTypes.string,
  rootStyle: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.bool,
  focus: PropTypes.bool,
}

TextInput.defaultProps = {
  value: "",
  rootStyle: "",
}

export default TextInput

import React, { Component } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import styles from "./index.css"

import consts from "consts"

import Button from "components/Button/Button"
import ContentHeader from "components/ContentHeader/ContentHeader"
import ContentBox from "components/ContentBox/ContentBox"

import { getAssetURL } from "helpers/static"

const notFoundImage = getAssetURL("image.pageErrors.notFound")

@connect(state => {
  return {
    appSettings: state.appSettings,
  }
})
export default class PageError extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className={styles.root}>
        <ContentHeader title={<span>Oups !</span>} image={{ src: notFoundImage }} />
        <div className={ContentHeader.above}>
          <ContentBox>
            <div className={classNames(ContentBox.hasTextCentered, styles.text)}>
              Devant l’affluence du nombre d’utilisateurs venant découvrir Molotov, vous rencontrez peut être des difficultés à utiliser notre
              service.
            </div>
            <div className={classNames(ContentBox.hasTextCentered, styles.text)}>
              Nous travaillons sans relâche pour résoudre le problème au plus vite. Nous vous prions de bien vouloir nous en excuser.
            </div>
            <div className={classNames(ContentBox.hasTextCentered, styles.text)}>
              Ne tapez pas sur votre écran !<br />
              La reprise des programmes est imminente…
            </div>
            {// test if not inFrame
            !this.props.appSettings.frameless && (
              <section className={styles.buttonsContainer}>
                <Link to={consts.routes.default}>
                  <Button customStyle={styles.button} color={Button.colors.black} value="Retour à l'accueil" />
                </Link>
              </section>
            )}
          </ContentBox>
        </div>
      </div>
    )
  }
}

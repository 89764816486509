import React from "react"

import Icon, { IconTypes } from "components/Icon/Icon"
import FormatterText from "containers/FormatterText"

const ICON_NAME = {
  "csa-18": "csa18",
  "csa-16": "csa16",
  "csa-12": "csa12",
  "csa-10": "csa10",
  card_gift: "gift",
}

const FormatterHelpers = {
  basic(str, values) {
    for (let key in values) {
      str = str.replace(":" + key, values[key])
    }

    str = str.replace(/[\(\)]/g, "")
    str = str[str.length - 1] === "?" ? str.substr(0, str.length - 1) : str

    return str
  },

  urlSplit(str) {
    const urlSplit = str.split("/")
    if (urlSplit.length > 5) {
      return {
        categoryId: urlSplit[2],
        categorySlug: urlSplit[3],
        genreId: urlSplit[4],
        genreSlug: urlSplit[5],
      }
    }
    return str.split("/")
  },

  sprintf(format, parts = []) {
    for (let i = 0, l = parts.length; i < l; i++) {
      format = format.replace("%s", typeof parts[i] !== "undefined" ? parts[i] : "")
    }
    return format
  },

  /**
   * Return a string given a string with /n and %s and associated parts, ingnoring styling
   *
   * @param {string} format -
   * @param {array} parts -
   * @return {string} - concatenated text
   */
  toTextOnly(format, parts = []) {
    let result = []
    const chunks = format.split("%s")

    chunks.forEach((text, index) => {
      result = result.concat(text)

      if (chunks.length - 1 > index) {
        const part = parts[index]
        result.push(part.title)
      }
    })

    return result.join("")
  },

  advanced(format, vars = {}) {
    const parts = format.split(/(\$\{[a-zA-Z0-9-_]*\})/)
    return (
      <div>
        {parts.map((part, index) => {
          const testing = part.match(/\$\{([a-zA-Z0-9-_]*)\}/)
          if (testing) {
            const matchingVar = vars[testing[1]]
            return <span key={index}>{matchingVar || part}</span>
          }
          return <span key={index}>{part}</span>
        })}
      </div>
    )
  },

  /**
   * Shortcut for "styled" that directly takes a formatter as parameter
   *
   * @param {object} formatter -
   * @param {string} idPrefix - a string used for react "key" on <br /> elements
   * @param {string} iconStyle - a string used for Icon component to set Style
   * @return {array} - react elements
   */
  styledFormatter(formatter, idPrefix = "", iconStyle) {
    return FormatterHelpers.styled(formatter.format, formatter.parts, idPrefix, iconStyle)
  },

  /**
   * Return a list of react elements given a string with %s and associated styling parts
   *
   * @param {string} format -
   * @param {array} parts -
   * @param {string} idPrefix - a string used for react "key" on <br /> elements
   * @param {string} iconStyle - a string used for Icon component to set Style
   * @return {array} - react elements
   */
  styled(format, parts = [], idPrefix = "", iconStyle) {
    if (!format) {
      return null
    }

    if (!parts.length) {
      return this.insertLineBreak(format, idPrefix)
    }

    let result = []
    const chunks = format.split("%s")

    chunks.forEach((text, index) => {
      result = result.concat(this.insertLineBreak(text, idPrefix + `chunk-${index}`))

      if (chunks.length - 1 > index) {
        const part = parts[index]

        if (!part) {
          console.error("Found a %s without a corresponding part in formatter: ") // eslint-disable-line no-console
          console.log(JSON.stringify(format)) // eslint-disable-line no-console
          console.log(JSON.stringify(parts)) // eslint-disable-line no-console
          return null
        }

        const style = {}

        if (part.color) {
          style.color = part.color
        }

        if (part.bold) {
          style.fontWeight = "bold"
        }

        result.push(
          <FormatterText style={style} key={`format-chunk-${index}`} part={part}>
            {part.type === "icon" && <Icon type={IconTypes[ICON_NAME[part.id]]} iconStyle={iconStyle} />}
            {part.title && this.insertLineBreak(part.title, idPrefix + `title-${index}`)}
          </FormatterText>
        )
      }
    })

    return result
  },

  /**
   * Replace \n with line breaks
   *
   * @param {string} format -
   * @param {string} idPrefix - a string used for react "key" on <br /> elements
   * @return {array} - react elements
   */
  insertLineBreak(format, idPrefix = "") {
    const lineChunks = format.split("\n")
    const spacedFormat = []

    lineChunks.forEach((c, i) => {
      spacedFormat.push(c)

      if (lineChunks.length - 1 > i) {
        spacedFormat.push(<br key={`br-${idPrefix}-${i}`} />)
      }
    })

    return spacedFormat
  },
}

export default FormatterHelpers

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { getPaypalTransactionToken } from "actions/paypal"
import consts from "consts"

import PaypalLogo from "./PaypalLogo"
import Loader from "../Loader/Loader"

import styles from "./recurlyPaypalButton.css"

const RecurlyPaypalButton = ({ submitRecurly }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { recurlyKey, braintreeKey } = consts.config

  const handleClick = () => {
    setLoading(true)

    dispatch(
      getPaypalTransactionToken(null, () => {
        window.recurly.configure(recurlyKey)
        const paypal = window.recurly.PayPal({
          display: {
            displayName: "Molotov TV",
          },
          braintree: {
            clientAuthorization: braintreeKey,
          },
        })
        paypal.on("token", token => {
          submitRecurly(token)
        })
        paypal.on("ready", () => {
          paypal.start()
          setLoading(false)
        })
        paypal.on("error", () => {
          // err.code
          // err.message
          // [err.cause] if there is an embedded error
          setLoading(false)
        })
      })
    )
  }

  return (
    <button className={styles.mockPaypalButton} onClick={handleClick} disabled={loading}>
      {loading && <Loader customStyle={styles.loader} centerInParent={true} />}
      {!loading && (
        <>
          Payer avec <PaypalLogo />
        </>
      )}
    </button>
  )
}

RecurlyPaypalButton.propTypes = {
  submitRecurly: PropTypes.func.isRequired,
}

export default RecurlyPaypalButton

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconArrowLeft = ({ customStyle = null }) => (
  <svg viewBox="0 0 10 13" className={classNames(customStyle, styles.fill)}>
    <path fillRule="evenodd" d="M6.42 0L0 6.44l6.44 6.46 1.38-1.36-5.08-5.08L7.82 1.4" />
  </svg>
)

IconArrowLeft.propTypes = {
  customStyle: PropTypes.string,
}

export default IconArrowLeft

export const productInitialState = {
  buyActionError: null,
  interstitial: null,
  passwordError: false,
  passwordForm: null,
  paymentForm: null,
  paymentPending: false,
  subscriptionSuccess: false,
  step: null,
}

const shallowMerge = (source, updates) => {
  // shallow clone source
  const result = { ...source }

  for (let prop in updates) {
    const value = updates[prop]
    if (value && typeof value === "object" && !Array.isArray(value)) {
      result[prop] = {
        ...result[prop],
        ...value,
      }
    } else {
      result[prop] = value
    }
  }

  return result
}

export const updateProduct = (state, equivalenceCode, newState) => {
  if (!equivalenceCode) {
    console.error("Cannot update payment state, missing equivalenceCode param") // eslint-disable-line no-console
    return state
  }

  return {
    ...state,
    [equivalenceCode]: state[equivalenceCode]
      ? shallowMerge(state[equivalenceCode], newState)
      : {
          ...productInitialState,
          ...newState,
        },
  }
}

export const getBrowserInfos = () => {
  return {
    color_depth: window.screen.colorDepth,
    java_enabled: false,
    javascript_enabled: true,
    language: navigator.language,
    screen_height: window.screen.availHeight,
    screen_width: window.screen.availWidth,
    time_zone_offset: new Date().getTimezoneOffset(),
    user_agent: navigator.userAgent,
    accept_header: "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
  }
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Control from "./Control/Control"
import controlType from "./Control/types"

import ResizeWatcher from "components/ResizeWatcher/ResizeWatcher"

import styles from "./carousel.css"
import { throttle } from "lodash"

export default class Carousel extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    customStyle: PropTypes.string,
    previewStyle: PropTypes.string,
    isHtmlContent: PropTypes.bool,
    autoplay: PropTypes.bool,
    height: PropTypes.number,
  }

  static defaultProps = {
    children: null,
    itemSizes: {},
    customStyle: "",
    previewStyle: "",
    isHtmlContent: false,
    autoplay: false,
  }

  state = {
    sliderWidth: 1000,
    itemWidth: 0,
    itemHeight: 0,
    count: this.props.children.length,
    posX: 0,
    step: 100,
    canPrev: false,
    canNext: true,
    displayMode: "carousel",
    lastMove: "",
  }

  autoplayInterval
  autoplayIntervalDuration = 5000
  defaultHorizontalMargin = 5
  staticModeHorizontalMargin = 22

  setStep = () => {
    const item = this.item
    const style = window.getComputedStyle(item)
    const itemWidth = parseFloat(style.marginLeft) + parseFloat(style.marginRight) + item.offsetWidth
    const itemHeight = this.props.height || parseFloat(style.marginTop) + parseFloat(style.marginBottom) + item.offsetHeight
    const sliderWidth = itemWidth * this.state.count

    this.setState({
      itemWidth,
      itemHeight,
      sliderWidth,
      step: itemWidth,
    })
  }

  autoplayMove = () => {
    const { lastMove, canNext, canPrev } = this.state
    if (lastMove === "") {
      this.next()
      this.setState({ lastMove: "next" })
    } else if (lastMove === "next") {
      if (canNext) {
        this.next()
      } else {
        this.prev()
        this.setState({ lastMove: "prev" })
      }
    } else {
      if (canPrev) {
        this.prev()
      } else {
        this.next()
        this.setState({ lastMove: "next" })
      }
    }
  }

  startAutoplay = () => {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval)
    }
    this.autoplayInterval = setInterval(this.autoplayMove, this.autoplayIntervalDuration)
  }

  next = e => {
    if (e && this.props.autoplay) {
      this.startAutoplay()
    }
    const { posX, step } = this.state

    const container = this.container
    const slider = this.slider
    const max = slider.lastChild.offsetLeft + slider.lastChild.offsetWidth - container.offsetWidth

    this.setState({
      canPrev: true,
    })

    if (posX - step > max * -1) {
      this.setState({
        posX: posX - step,
      })
    } else {
      this.setState({
        posX: -1 * max,
        canNext: false,
      })
    }
  }

  prev = e => {
    if (e && this.props.autoplay) {
      this.startAutoplay()
    }
    const { posX, step } = this.state

    this.setState({
      canNext: true,
    })

    if (posX + step < 0) {
      this.setState({
        posX: posX + step,
        canPrev: true,
      })
    } else {
      this.setState({
        posX: 0,
        canPrev: false,
      })
    }
  }

  getContainerWidth = () => {
    return parseInt(window.getComputedStyle(this.container).width, 10)
  }

  getBestMarginForItems = displayMode => {
    if (displayMode === "carousel") return this.defaultHorizontalMargin

    const containerWidth = this.getContainerWidth()
    const itemWidth = this.item.offsetWidth
    const bestMargin = (containerWidth - itemWidth * this.state.count) / (2 * this.state.count)

    return Math.floor(bestMargin * 10) / 10
  }

  needCarouselMode = () => {
    const containerWidth = this.getContainerWidth()
    const itemWidth = this.item.offsetWidth
    const defaultAllItemsWidth = (itemWidth + this.defaultHorizontalMargin * 2) * this.state.count

    return defaultAllItemsWidth > containerWidth
  }

  handleResize = () => {
    this.setStep()
    if (this.state.displayMode === "carousel" && !this.needCarouselMode()) {
      this.setState({
        displayMode: "static",
        canNext: null,
        canPrev: null,
        posX: 0,
      })
    } else if (this.state.displayMode === "static") {
      if (this.needCarouselMode()) {
        this.setState({
          displayMode: "carousel",
          canNext: true,
          canPrev: false,
        })
      } else {
        this.forceUpdate()
      }
    }
  }

  onTouchEnd = ev => {
    if (this.sX < ev.changedTouches[0].clientX) {
      this.prev()
    } else {
      return this.next()
    }
  }

  touch = ev => {
    let touche = ev.touches[0]
    this.sX = touche.clientX
  }

  onWheelThrottled = throttle(() => {
    if (this.deltaX === 0 || this.deltaY !== 0 || this.deltaZ !== 0) return

    const absDeltaX = Math.abs(this.deltaX)
    const _lastDeltaX = this.lastDeltaX

    this.lastDeltaX = absDeltaX

    if (absDeltaX < 5 || this.isScrolling || absDeltaX <= _lastDeltaX) {
      return
    }

    this.isScrolling = true

    clearTimeout(this.scrollTimeout)

    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = false
    }, 120)

    if (this.deltaX > 0) return this.next()
    if (this.deltaX < 0) return this.prev()
  }, 80)

  onWheel = ev => {
    this.deltaX = ev.deltaX
    this.deltaY = ev.deltaY
    this.deltaZ = ev.deltaZ
    this.onWheelThrottled()
  }

  componentDidMount() {
    this.setStep()
    this.handleResize()
    if (this.props.autoplay && this.state.count > 1) {
      this.startAutoplay()
    }
    this.container.addEventListener("touchstart", this.touch, false)
    this.container.addEventListener("touchend", this.onTouchEnd, false)
    this.container.addEventListener("wheel", this.onWheel, { passive: true })
  }

  componentWillUnmount() {
    this.container.removeEventListener("touchstart", this.touch)
    this.container.removeEventListener("touchend", this.onTouchEnd)
    this.container.removeEventListener("wheel", this.onWheel)
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval)
    }
  }

  render() {
    const { children, customStyle, previewStyle } = this.props

    const { sliderWidth, itemHeight, posX, canPrev, canNext } = this.state

    return (
      <ResizeWatcher onResize={this.handleResize}>
        <div ref={container => (this.container = container)} className={classNames(styles.root, customStyle)}>
          {canPrev && <Control action={this.prev} type={controlType.prev} isHtmlContent={this.props.isHtmlContent} />}

          {canNext && <Control action={this.next} type={controlType.next} isHtmlContent={this.props.isHtmlContent} />}

          <div
            className={styles.slider}
            ref={slider => (this.slider = slider)}
            style={{
              width: this.state.displayMode === "static" ? "100%" : sliderWidth,
              height: itemHeight,
              transform: `translateX(${posX}px)`,
            }}
          >
            {children.map((child, key) => {
              const itemMargin = this.props.isHtmlContent ? "0" : this.getBestMarginForItems(this.state.displayMode)
              const itemStyle = {
                margin: `0 ${itemMargin}px`,
              }
              return (
                <div ref={item => (this.item = item)} className={classNames(styles.item, previewStyle)} key={key} style={itemStyle}>
                  {child}
                </div>
              )
            })}
          </div>
        </div>
      </ResizeWatcher>
    )
  }
}

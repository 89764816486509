import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Components
import FormLabel from "components/FormGroup/FormLabel/FormLabel"
import RadioGroup from "components/RadioGroup/RadioGroup"

// Consts
import i18n from "consts/i18n"
import styles from "./genderForm.css"

const GenderForm = ({ error, errorMessages, gender, onChange, errorStyle = "" }) => (
  <div>
    <FormLabel customStyle={styles.label} title={i18n.signupPage.gender} />
    <RadioGroup
      rootStyle={classNames(styles.gender, { [styles.error]: error })}
      name="sex"
      values={[
        { value: "M", valueDisplayed: i18n.profile.male },
        { value: "F", valueDisplayed: i18n.profile.female },
      ]}
      selectedValue={gender}
      buttonStyle={styles.radio}
      onChange={onChange}
    />
    {error && <div className={classNames(styles.errorMessage, errorStyle)}>{errorMessages.ERR_ACCOUNT_CREATION_MISSING_GENDER}</div>}
  </div>
)

GenderForm.propTypes = {
  error: PropTypes.bool,
  errorMessages: PropTypes.object,
  gender: PropTypes.string,
  onChange: PropTypes.func,
  errorStyle: PropTypes.string,
}

export default GenderForm

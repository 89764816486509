import {
  APP_ERROR,
  TOGGLE_HEADER_MENU,
  HIDE_HEADER_MENU,
  HIDE_NAV,
  SHOW_SEO_NAV,
  HIDE_SEO_NAV,
  TOGGLE_SEO_MENU,
  CLOSE_SEO_SUB_MENU,
  SET_WINDOW_HEIGHT,
  SET_WINDOW_WIDTH,
  SET_DOCUMENT_HEIGHT,
  SCROLL,
  SET_LOGO_OFFSET_TOP,
  ALLOW_CLIENT_RENDER,
  SET_SEO_CTA_FOOTER_HEIGHT,
  SET_404_ERROR,
  TOGGLE_MOBILE_NAV,
  CLEAR_BACKGROUND_IMAGE,
} from "consts/actions"

export function toggleUserMenu() {
  return {
    type: TOGGLE_HEADER_MENU,
  }
}

export function hideUserMenu() {
  return (dispatch, getState) => {
    const currentState = getState()
    if (currentState.ui.headerMenuVisible === true) {
      dispatch({
        type: HIDE_HEADER_MENU,
      })
    }
  }
}

export function hideNav() {
  return {
    type: HIDE_NAV,
  }
}

export function scroll(scrollTop) {
  return {
    type: SCROLL,
    payload: scrollTop,
  }
}

export function showSEONav() {
  return {
    type: SHOW_SEO_NAV,
  }
}

export function hideSEONav() {
  return (dispatch, getState) => {
    const ui = getState().ui

    if (ui.seoNavVisible || Object.keys(ui.seoMenuVisible).length) {
      dispatch({
        type: HIDE_SEO_NAV,
      })
    }
  }
}

export function toggleSEOMenu(id, subMenu) {
  return {
    type: TOGGLE_SEO_MENU,
    id,
    subMenu,
  }
}

export function closeSubMenu(id) {
  return {
    type: CLOSE_SEO_SUB_MENU,
    payload: id,
  }
}

export function setWindowHeight(height) {
  return (dispatch, getState) => {
    if (getState().ui.windowHeight !== height) {
      dispatch({
        type: SET_WINDOW_HEIGHT,
        payload: height,
      })
    }
  }
}

export function setWindowWidth(width) {
  return {
    type: SET_WINDOW_WIDTH,
    payload: width,
  }
}

export function setDocumentHeight(height) {
  return (dispatch, getState) => {
    if (getState().ui.documentHeight !== height) {
      dispatch({
        type: SET_DOCUMENT_HEIGHT,
        payload: height,
      })
    }
  }
}

export function setLogoOffsetTop(logoOffsetTop) {
  return {
    type: SET_LOGO_OFFSET_TOP,
    payload: logoOffsetTop,
  }
}

export function allowClientRender() {
  return {
    type: ALLOW_CLIENT_RENDER,
  }
}

export function setSEOCTAFooterHeight(height) {
  return (dispatch, getState) => {
    if (getState().ui.seoCTAFooterHeight !== height) {
      dispatch({
        type: SET_SEO_CTA_FOOTER_HEIGHT,
        height,
      })
    }
  }
}

export function catchError() {
  return {
    type: APP_ERROR,
  }
}

export function set404Error() {
  return {
    type: SET_404_ERROR,
  }
}

export function clearBackgroundImage() {
  return {
    type: CLEAR_BACKGROUND_IMAGE,
  }
}

export function toggleMobileNav() {
  return {
    type: TOGGLE_MOBILE_NAV,
  }
}

import { GET_UNSUBSCRIBE_DATA_PENDING, GET_UNSUBSCRIBE_DATA_SUCCESS, GET_UNSUBSCRIBE_DATA_FAILED } from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNSUBSCRIBE_DATA_PENDING:
      return stateHelper.pending()

    case GET_UNSUBSCRIBE_DATA_SUCCESS:
      return { data: action.payload.data }

    case GET_UNSUBSCRIBE_DATA_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}

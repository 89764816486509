import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconPhone = ({ customStyle = null }) => (
  <svg viewBox="-4 0 20 20" className={classNames(customStyle, styles.fill)}>
    <path
      fillRule="evenodd"
      d="M10.535 3.704H1.317v-.74c0-.82.59-1.483 1.317-1.483h6.584c.727 0 1.317.665 1.317 1.483v.74zm0 12.592H1.317v.74c0 .82.59 1.483 1.317 1.483h6.584c.727 0 1.317-.665 1.317-1.483v-.74zM1.317 5.186h9.218v9.63H1.317v-9.63zM9.217 0H2.635C1.18 0 0 1.12 0 2.5v15C0 18.88 1.18 20 2.634 20h6.584c1.455 0 2.634-1.12 2.634-2.5v-15c0-1.38-1.18-2.5-2.634-2.5z"
    />
  </svg>
)

IconPhone.propTypes = {
  customStyle: PropTypes.string,
}

export default IconPhone

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import RouterSwitch from "components/RouterSwitch/RouterSwitch"

import styles from "./index.css"

import Nav from "components/Nav/Nav"

@connect(state => ({
  showMobileNav: state.ui.showMobileNav,
}))
export default class PublicWrapper extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    showMobileNav: PropTypes.bool,
    serverCookies: PropTypes.string,
  }

  render() {
    const { location, showMobileNav } = this.props
    return (
      <div>
        {showMobileNav ? (
          <Nav location={location} />
        ) : (
          <div className={styles.content}>
            <RouterSwitch groupName="publicRoutes" serverCookies={this.props.serverCookies} />
          </div>
        )}
      </div>
    )
  }
}

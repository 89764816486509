import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconFacebook = ({ customStyle = null }) => (
  <svg viewBox="0 0 470.513 470.513" className={classNames(customStyle, styles.fill)}>
    <path d="M271.52 154.17v-40.54c0-6.087.28-10.8.85-14.13.567-3.336 1.857-6.616 3.86-9.854 1.998-3.236 5.235-5.47 9.705-6.708 4.476-1.24 10.424-1.858 17.85-1.858h40.54V0h-64.81c-37.5 0-64.433 8.897-80.803 26.69-16.368 17.8-24.55 44.015-24.55 78.66v48.82h-48.543v81.085h48.538V470.51h97.362V235.257h64.805l8.566-81.086h-73.37z" />
  </svg>
)

IconFacebook.propTypes = {
  customStyle: PropTypes.string,
}

export default IconFacebook

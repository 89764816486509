import thunkMiddleware from "redux-thunk"
import { routerMiddleware } from "connected-react-router"

// Actions
import { invalidAccessToken, logout } from "actions/session"

import LogManager from "managers/logManager"

// Selectors
import selectAuthentication from "selectors/authentication"

// Custom middleware
import requestQueueMiddleware from "middlewares/requestQueue"
import superagentMiddleware from "@molotovtv/js-redux-superagent-middleware"
import iframeMessageMiddleware from "middlewares/iframeMessage"

import segmentHelpers from "helpers/segment"

function featuresSupported() {
  const featuresSupported = ["parental_control_v3", "allow_recurly"]

  return featuresSupported
}

export default function getMiddlewares(consts, history, options = {}) {
  const superagentMiddlewareHooks = options.superagentMiddlewareHooks || null

  const molotovAgentType = options.navigator ? segmentHelpers.getDeviceTypeFromNavigator(options.navigator) : consts.devicesType.desktop
  const molotovAgentOS = options.navigator ? segmentHelpers.getOsFromNavigator(options.navigator) : consts.molotovAgent.os

  // Create an instance for superagentMiddleware
  const superagentMiddlewareInstance = superagentMiddleware({
    base: consts.config.apiPath,
    extensions: options.extensions,
    requestModifier: options.requestModifier,
    timeout: options.timeout,
    loggerInfo: options.loggerInfo,
    defaultHeaders: {
      ["Accept"]: "application/json",
      ["Content-type"]: "application/json",
      ["X-Molotov-Agent"]: options.molotovAgent
        ? JSON.stringify(options.molotovAgent)
        : JSON.stringify({
            app_build: consts.molotovAgent.appBuild,
            app_id: consts.molotovAgent.appId,
            api_version: consts.molotovAgent.apiVersion,
            type: molotovAgentType,
            os: molotovAgentOS,
            manufacturer: consts.molotovAgent.manufacturer,
            model: consts.molotovAgent.model,
            brand: consts.molotovAgent.brand,
            serial: consts.molotovAgent.serial,
            features_supported: featuresSupported(),
          }),
      ["X-Molotov-Website"]: consts.molotovAgent.appId,
    },
    hooks: {
      onRequest: (store, action, request) => {
        const state = store.getState()
        const authentication = selectAuthentication(state)
        if (action.accessTokenRequired !== false) {
          const token = action.forceToken || authentication.accessToken
          request.params["access_token"] = token
        }

        superagentMiddlewareHooks && superagentMiddlewareHooks.onRequest(store, action, request)

        return request
      },
      onResponse: (store, action, request, response, fromCache) => {
        superagentMiddlewareHooks && superagentMiddlewareHooks.onResponse(store, action, request, response, fromCache)
      },
      onError: (store, action, request, response) => {
        if (!response) return

        if (
          response.body &&
          response.body.error &&
          response.body.error.error_code === consts.api.errorCodes.TOKEN_EXPIRED &&
          action.queueing !== false
        ) {
          store.dispatch(invalidAccessToken())
          store.dispatch(action)
          return false
        }
        if (response.body && response.body.error && response.body.error.error_code === consts.api.errorCodes.LOGOUT_MANDATORY) {
          LogManager.ERROR("LOGOUT_MANDATORY received from API", {
            error: response.body.error,
          })
          store.dispatch(logout())
          return false
        }
      },
    },
  })

  return [
    ...(options.prepend || []),
    thunkMiddleware,
    requestQueueMiddleware,
    superagentMiddlewareInstance,
    thunkMiddleware,
    routerMiddleware(history),
    iframeMessageMiddleware,
  ]
}

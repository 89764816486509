import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconArrowsLoop = ({ customStyle = null }) => (
  <svg viewBox="0 0 13 16" className={classNames(customStyle, styles.fill)}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd" fill="#000000" transform="translate(1.000000, 1.000000)">
      <path d="M8.69742332,2.51669273 L8.01643105,3.26453588 C9.46985275,4.23335939 10.3144086,6.00841722 10.0273883,7.863679 C9.83824217,9.08653763 9.19174515,10.1614049 8.20739277,10.8901199 C7.34814756,11.5262144 6.32088244,11.8322647 5.27331629,11.7713566 L5.45321972,11.5794041 C5.44975369,11.5792363 5.44595757,11.5797397 5.44249154,11.5795719 L6.12397895,10.8527026 L5.49464703,10.2421122 L4.14322563,11.6844411 L4.14289553,11.6839377 L3.55977733,12.3064412 L4.18877916,12.9165282 L4.18910926,12.9163604 L5.60737551,14.2903984 L6.19098886,13.6678949 L5.27249104,12.7782686 C6.5295044,12.8386733 7.75911962,12.4673525 8.79051097,11.7039048 C9.98893193,10.8169631 10.7757206,9.50853125 11.0061291,8.01989215 C11.3456349,5.82133059 10.3810884,3.71488613 8.69742332,2.51669273 L8.69742332,2.51669273 Z" />
      <path d="M1.05878948,6.42671944 C1.24793566,5.20386081 1.89443269,4.12899358 2.87878506,3.40027856 C3.73803028,2.76418401 4.76529539,2.45813377 5.81286154,2.5190418 L5.63295811,2.71099436 C5.63642414,2.71116215 5.64022027,2.71065878 5.6436863,2.71082657 L4.96219888,3.43769589 L5.5915308,4.04828625 L6.94295221,2.60595738 L6.9432823,2.60646075 L7.5264005,1.98395725 L6.89739868,1.37387026 L6.89706858,1.37403805 L5.47880232,0 L4.89518898,0.622503502 L5.81368679,1.5121298 C4.55667343,1.45172515 3.32705821,1.82304597 2.29566686,2.58649366 C1.09724591,3.47343531 0.310457212,4.78186719 0.0800487762,6.2705063 C-0.259952211,8.46923564 0.704759329,10.5756801 2.38825936,11.7738735 L3.06925163,11.0260303 C1.61632508,10.0568713 0.771769232,8.28198122 1.05878948,6.42671944 L1.05878948,6.42671944 Z" />
    </g>
  </svg>
)

IconArrowsLoop.propTypes = {
  customStyle: PropTypes.string,
}

export default IconArrowsLoop

import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { connect } from "react-redux"

import Separator from "components/Separator/Separator"
import Icon, { IconTypes } from "components/Icon/Icon"
import InteractionButton from "containers/InteractionButton"

import apiAction from "helpers/apiAction"
import formatterHelper from "helpers/formatter"

import consts from "consts"
import styles from "./index.css"

@connect()
export default class ProductConfirmationDesktop extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    subscriptionMessages: PropTypes.object,
  }

  static defaultProps = {
    customStyle: "",
  }

  handleOptionClick = option => {
    this.props.dispatch(apiAction.handleAction(option.button, consts.apiActionTypes.onClick))
  }

  render() {
    const { customStyle, subscriptionMessages } = this.props

    return (
      <div className={styles.root}>
        <div className={classNames(styles.leftSide, customStyle)}>
          <p className={styles.subtitle}>{formatterHelper.styledFormatter(subscriptionMessages?.subtitle)}</p>
          <Separator customStyle={styles.separator} />
          <p className={styles.summaryTitle}>{subscriptionMessages?.summary?.title}</p>
          <img className={styles.summaryLogo} src={subscriptionMessages?.summary?.product_logo?.url} alt="summary logo" />
          <p className={styles.summarySbtitle}>{subscriptionMessages?.summary?.pricing}</p>
          <p className={styles.summarySbtitle}>{formatterHelper.styledFormatter(subscriptionMessages?.summary?.pricing_subtitle)}</p>
          <p className={styles.summaryEmail}>{formatterHelper.styledFormatter(subscriptionMessages?.summary?.email_and_help)}</p>
          <Separator customStyle={styles.separator} />
          {subscriptionMessages?.buttons &&
            subscriptionMessages?.buttons.map((button, index) => (
              <InteractionButton
                key={index}
                button={{ ...button, color: button.style }}
                customStyle={classNames(styles.button, {
                  [styles.linkButton]: button.style === "button_link",
                })}
              />
            ))}
        </div>
        <div className={styles.rightSide}>
          <div className={styles.deviceContainer}>
            <p className={styles.deviceTitle}>{subscriptionMessages?.devices?.title}</p>
            <img src={subscriptionMessages?.devices?.picture?.url} className={styles.deviceImage} alt="device image" />
            <InteractionButton
              button={{ ...subscriptionMessages?.devices?.button, color: subscriptionMessages?.devices?.button.style }}
              customStyle={classNames(styles.button, {
                [styles.linkButton]: subscriptionMessages?.devices?.button.style === "button_link",
              })}
            />
          </div>
          {subscriptionMessages?.suggested_options && (
            <div className={styles.optionsContainer}>
              <p className={styles.optionTitle}>{subscriptionMessages?.suggested_options?.title}</p>
              {subscriptionMessages?.suggested_options?.options &&
                subscriptionMessages?.suggested_options?.options.slice(0, 2).map((option, index) => (
                  <div key={index} className={styles.optionContainer}>
                    <div className={styles.optionDescription}>
                      <p className={styles.optionName}>{option.title}</p>
                      <p className={styles.optionSubtitle}>{option.subtitle}</p>
                    </div>
                    <Icon type={IconTypes.addCircle} onClick={() => this.handleOptionClick(option)} />
                  </div>
                ))}
              <InteractionButton
                button={{ ...subscriptionMessages?.suggested_options?.button, color: subscriptionMessages?.suggested_options?.button.style }}
                customStyle={classNames(styles.suggestedOptionsButton, {
                  [styles.linkButton]: subscriptionMessages?.suggested_options?.button.style === "button_link",
                })}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

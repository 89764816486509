import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconCrossedEye = ({ customStyle = null }) => (
  <svg viewBox="0 0 17 14" className={classNames(customStyle, styles.fill)}>
    <g fill="none" fillRule="evenodd" opacity=".2">
      <path
        fill="#000000"
        d="M8.49739264,1.02804323 C5.25035787,1.02804323 2.3057771,3.00460751 0.13297546,6.21507395 C-0.0443251534,6.47810011 -0.0443251534,6.84556314 0.13297546,7.10858931 C2.3057771,10.3229238 5.25035787,12.2994881 8.49739264,12.2994881 C11.7444274,12.2994881 14.6890082,10.3229238 16.8618098,7.11245734 C17.0391104,6.84943117 17.0391104,6.48196815 16.8618098,6.21894198 C14.6890082,3.00460751 11.7444274,1.02804323 8.49739264,1.02804323 L8.49739264,1.02804323 Z M8.73031697,10.6323663 C6.57489775,10.7832196 4.79493865,8.80665529 4.93052147,6.40460751 C5.04176892,4.4241752 6.4845092,2.81894198 8.2644683,2.69516496 C10.4198875,2.54431172 12.1998466,4.520876 12.0642638,6.92292378 C11.9495399,8.89948805 10.5067996,10.5047213 8.73031697,10.6323663 L8.73031697,10.6323663 Z M8.62254601,8.79891923 C7.46140082,8.8801479 6.50189162,7.81643914 6.57837423,6.5245165 C6.63747444,5.4569397 7.41620654,4.5943686 8.37571575,4.52474403 C9.53686094,4.44351536 10.4963701,5.50722412 10.4198875,6.79914676 C10.3573108,7.87059158 9.57857873,8.73316268 8.62254601,8.79891923 L8.62254601,8.79891923 Z"
      />
      <path stroke="#000000" d="M15.8416641,0.158335936 L2.5,13.5" strokeLinecap="square" />
    </g>
  </svg>
)

IconCrossedEye.propTypes = {
  customStyle: PropTypes.string,
}

export default IconCrossedEye

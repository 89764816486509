import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./panel.css"

const Panel = ({ children = null, customStyle = "" }) => {
  return <div className={classNames(styles.root, customStyle)}>{children}</div>
}

Panel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  customStyle: PropTypes.string,
}

export default Panel

import {
  GET_GIFT_CONGRATS_PENDING,
  GET_GIFT_CONGRATS_SUCCESS,
  GET_GIFT_CONGRATS_FAILED,
  GET_GIFT_BUY_PENDING,
  GET_GIFT_BUY_SUCCESS,
  GET_GIFT_BUY_FAILED,
  GET_GIFT_CLAIM_PENDING,
  GET_GIFT_CLAIM_SUCCESS,
  GET_GIFT_CLAIM_FAILED,
  GET_GIFT_OPTIONS_PENDING,
  GET_GIFT_OPTIONS_SUCCESS,
  GET_GIFT_OPTIONS_FAILED,
  VALIDATE_GIFT_SUCCESS,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GIFT_CONGRATS_PENDING:
      return { congrats: stateHelper.pending() }

    case GET_GIFT_CONGRATS_SUCCESS:
      return { congrats: action.payload.data }

    case GET_GIFT_BUY_PENDING:
      return { buy: stateHelper.pending() }

    case GET_GIFT_BUY_SUCCESS:
      return { buy: action.payload.data }

    case GET_GIFT_CLAIM_PENDING:
      return {
        ...state,
        claim: stateHelper.pending(),
      }

    case GET_GIFT_CLAIM_SUCCESS:
      return {
        ...state,
        claim: action.payload.data,
      }

    case GET_GIFT_OPTIONS_PENDING:
      return {
        ...state,
        options: stateHelper.pending(),
      }

    case GET_GIFT_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload.data,
      }

    case GET_GIFT_BUY_FAILED:
    case GET_GIFT_CONGRATS_FAILED:
    case GET_GIFT_OPTIONS_FAILED:
    case GET_GIFT_CLAIM_FAILED:
      return {
        ...state,
        options: { error: action.payload },
      }

    case VALIDATE_GIFT_SUCCESS:
      return {
        ...state,
        validation: action.payload,
      }

    default:
      return state
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconCheckmark = ({ customStyle = null, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={classNames(customStyle, styles.fill)} width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fill || "#000"}
        data-identifier="checkmark_path"
        d="M7.955 18.465l-.005.006-3.89-3.889a1.5 1.5 0 1 1 2.122-2.121l3.252 3.251 9.551-9.551a1.5 1.5 0 1 1 2.122 2.121L10.5 18.889a1.5 1.5 0 0 1-2.121 0l-.424-.424z"
      />
    </g>
  </svg>
)

IconCheckmark.propTypes = {
  customStyle: PropTypes.string,
  fill: PropTypes.string,
}

export default IconCheckmark

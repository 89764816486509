import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconTrash = ({ customStyle = null }) => (
  <svg viewBox="0 0 24 24" className={classNames(customStyle, styles.fill)}>
    <path
      d="m6 6h12v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2-2zm3.764-3c.55-.614 1.348-1 2.236-1s1.687.386 2.236 1h3.764a2 2 0 0 1 2 2h-16a2 2 0 0 1 2-2zm-.014 6a.75.75 0 0 0 -.75.75v8.5a.75.75 0 1 0 1.5 0v-8.5a.75.75 0 0 0 -.75-.75zm4.5 0a.75.75 0 0 0 -.75.75v8.5a.75.75 0 1 0 1.5 0v-8.5a.75.75 0 0 0 -.75-.75z"
      fillRule="evenodd"
    />
  </svg>
)

IconTrash.propTypes = {
  customStyle: PropTypes.string,
}

export default IconTrash

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import PageLoginDumb from "components/PageLogin/PageLogin"

// Actions
import { passwordLogin, facebookLogin, googleLogin, appleLogin, clearLoginError } from "actions/session"

// Selectors
import selectAuthentication from "selectors/authentication"

import routeHelpers from "helpers/route"
import TrackingSegment from "managers/segmentManager"

@connect(state => ({
  authentication: selectAuthentication(state),
}))
export default class PageLogin extends Component {
  static propTypes = {
    onClickRegister: PropTypes.func,
    deviceTargetAgent: PropTypes.object,
    location: PropTypes.object,
    loginOrigin: PropTypes.string,
    authentication: PropTypes.object.isRequired,
    noAccountComponent: PropTypes.node,
    onSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    errorMessages: PropTypes.object,
    showGoogleConnect: PropTypes.bool,
    title: PropTypes.node,
    fromOAuthPage: PropTypes.bool,
    fromEchoShow: PropTypes.bool,
    showOpenEurope: PropTypes.bool,
  }

  static defaultProps = {
    showGoogleConnect: true,
    fromEchoShow: false,
    showOpenEurope: false,
  }

  state = {
    loginFormDisabled: false,
  }

  getContextData = () => {
    return {
      originPage: this.props.loginOrigin,
      deviceTargetAgent: this.props.deviceTargetAgent,
    }
  }

  onSubmit = () => {
    this.props.dispatch(passwordLogin(this.state._email, this.state._password, this.getContextData()))
  }

  onFacebookButtonClick = () => {
    this.props.dispatch(clearLoginError())
    this.setState({ loginFormDisabled: true })
  }

  onFacebookResponse = response => {
    if (response && response.accessToken) {
      this.props.dispatch(facebookLogin(response.accessToken, this.getContextData()))
    }
    this.setState({ loginFormDisabled: false })
  }

  onGoogleLogin = data => {
    this.props.dispatch(googleLogin(data.code, data.googleId, this.getContextData()))
  }

  onAppleLogin = data => {
    this.props.dispatch(appleLogin(data, this.getContextData()))
  }

  onInputChange = (value, name) => {
    this.setState({
      [`_${name}`]: value,
    })
  }

  componentDidMount() {
    if (routeHelpers.isGiftReceiverPage(this.props.location.pathname)) {
      TrackingSegment.trackPage("gift_receiver", {
        gift_receiver_step: "log_in",
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearLoginError())
  }

  render() {
    const { authentication, onClickRegister, noAccountComponent, errorMessages, showGoogleConnect, title, fromEchoShow, showOpenEurope } = this.props

    const { loginFormDisabled } = this.state

    return (
      <PageLoginDumb
        title={title}
        authentication={authentication}
        loginFormDisabled={loginFormDisabled}
        noAccountComponent={noAccountComponent}
        errorMessages={errorMessages}
        showGoogleConnect={showGoogleConnect}
        fromOAuthPage={this.props.fromOAuthPage}
        onSubmit={this.onSubmit}
        onClickRegister={onClickRegister}
        onInputChange={this.onInputChange}
        onFacebookButtonClick={this.onFacebookButtonClick}
        onFacebookResponse={this.onFacebookResponse}
        onGoogleLogin={this.onGoogleLogin}
        onAppleLogin={this.onAppleLogin}
        fromEchoShow={fromEchoShow}
        showOpenEurope={showOpenEurope}
      />
    )
  }
}

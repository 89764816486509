import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Separator from "components/Separator/Separator"
import Button from "components/Button/Button"

import selectBookmarksDelete from "selectors/bookmarksDelete"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect(state => ({
  bookmarksDelete: selectBookmarksDelete(state),
}))
export default class ModalBookmarkDeleteConfirm extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    action: PropTypes.func,
    confirmButtonStyle: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    messageComponent: PropTypes.element,
    bookmarksDelete: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    close: () => {},
    action: () => {},
    confirmButtonStyle: "yellowFull",
    title: i18n.modalConfirm.title,
    message: null,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookmarksDelete.pending && !this.props.bookmarksDelete.pending) {
      this.props.close()
    }
  }

  render() {
    const { action, close, confirmButtonStyle, message, messageComponent, title, bookmarksDelete } = this.props

    return (
      <div>
        <h3>{title}</h3>

        <Separator customStyle={styles.separator} />

        {bookmarksDelete.pending ? (
          <span>{i18n.modalDeleteSelectedBookmarks.deletePending}</span>
        ) : (
          (message && <p className={styles.paragraph}>{message}</p>) || messageComponent
        )}

        {bookmarksDelete.pending ? null : (
          <div>
            {(message || messageComponent) && <Separator customStyle={styles.separator} />}
            <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={close} value={i18n.cancel} />
            <Button color={Button.colors[confirmButtonStyle]} customStyle={styles.rightButton} onClick={action} value={i18n.delete} />
          </div>
        )}
      </div>
    )
  }
}

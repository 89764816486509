import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const Invisible = ({ customStyle = null }) => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={classNames(customStyle, styles.invisible)}
  >
    <path
      d="M8 4.667A3.335 3.335 0 0 1 11.333 8c0 .433-.087.84-.24 1.22l1.947 1.947A7.878 7.878 0 0 0 15.327 8c-1.154-2.927-4-5-7.334-5-.933 0-1.827.167-2.653.467l1.44 1.44c.38-.154.786-.24 1.22-.24zm-6.667-1.82 1.52 1.52.307.306A7.87 7.87 0 0 0 .667 8c1.153 2.927 4 5 7.333 5 1.033 0 2.02-.2 2.92-.56l.28.28 1.953 1.947.847-.847L2.18 2l-.847.847zM5.02 6.533l1.033 1.034A1.88 1.88 0 0 0 6 8c0 1.107.893 2 2 2 .146 0 .293-.02.433-.053l1.034 1.033c-.447.22-.94.353-1.467.353A3.335 3.335 0 0 1 4.667 8c0-.527.133-1.02.353-1.467zm2.873-.52 2.1 2.1.014-.106c0-1.107-.894-2-2-2l-.114.006z"
      fill="#000"
      fillOpacity=".3"
    />
  </svg>
)

Invisible.propTypes = {
  customStyle: PropTypes.string,
}

export default Invisible

import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import classnames from "classnames"

import Button from "components/Button/Button"
import modalTypes from "containers/Modal/types"

import { open as openModal } from "actions/modal"
import { applyPrivacySettings, hideCookieBanner, setPrivacySettings } from "actions/privacy"

import cookieHelper from "helpers/cookies"
import urlHelper from "helpers/url"

import styles from "./cookieBanner.css"
import consts from "consts"
import i18n from "consts/i18n"

@connect(state => ({
  privacy: state.privacy,
}))
export default class CookieBanner extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    privacy: PropTypes.object,
  }

  onAccept = () => {
    this.props.dispatch(
      setPrivacySettings({
        [consts.privacy.acceptTracking]: true,
        [consts.privacy.acceptAds]: true,
      })
    )
    if (window.clickId) {
      cookieHelper.setCookie({
        name: consts.cookieNames.clickId,
        value: window.clickId,
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30).toGMTString(),
        domain: urlHelper.getCurrentDomainWithExtension(),
        sameSite: "Lax",
      })
    }
    this.props.dispatch(applyPrivacySettings())
    this.props.dispatch(hideCookieBanner())
  }

  onClose = () => {
    this.props.dispatch(applyPrivacySettings())
    this.props.dispatch(hideCookieBanner())
    this.props.dispatch(
      setPrivacySettings({
        [consts.privacy.acceptTracking]: false,
        [consts.privacy.acceptAds]: false,
      })
    )
  }

  openCookieModal = () => {
    this.props.dispatch(openModal(modalTypes.cookie, null, null, false))
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.title}>Gestion des cookies</div>
          <div className={styles.text}>
            <div className={styles.ratingList} dangerouslySetInnerHTML={{ __html: i18n.cookieSettings.bannerMessage1 }} />
            <Link className={styles.link} to={consts.routes.privacy} target="_blank">
              {i18n.privacyPolicy}
            </Link>{" "}
            {i18n.cookieSettings.bannerMessage2}
            <Link className={styles.link} to={consts.routes.cookies} target="_blank">
              {i18n.cookies}
            </Link>
            .
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              color={Button.colors.greyFull}
              customStyle={classnames(styles.button, styles.buttonTop)}
              value={i18n.cookieSettings.refuse}
              onClick={this.onClose}
            />
            <Button color={Button.colors.yellowFull} customStyle={styles.button} value={i18n.cookieSettings.accept} onClick={this.onAccept} />
          </div>
          <div className={styles.textMobile}>
            <div className={styles.ratingList} dangerouslySetInnerHTML={{ __html: i18n.cookieSettings.bannerMessage1Mobile }} />
          </div>
          <div className={styles.buttonWrapperMobile}>
            <Button
              color={Button.colors.yellowFull}
              customStyle={classnames(styles.button, styles.buttonTop)}
              value={i18n.cookieSettings.accept}
              onClick={this.onAccept}
            />
            <Button color={Button.colors.greyFull} customStyle={styles.button} value={i18n.cookieSettings.refuse} onClick={this.onClose} />
          </div>
          <div className={styles.custom}>
            <span
              className={styles.link}
              onClick={() => {
                this.openCookieModal()
                window.Didomi.preferences.show()
              }}
            >
              {i18n.cookieSettings.editSettings}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./formLabel.css"

const FormLabel = ({ title = null, customStyle = null }) => {
  return <label className={classnames(styles.root, customStyle)}>{title}</label>
}

FormLabel.propTypes = {
  title: PropTypes.string.isRequired,
  customStyle: PropTypes.string,
}

export default FormLabel

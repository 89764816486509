import classNames from "classnames"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import styles from "./index.css"
import consts from "consts"

import ContentHeader from "components/ContentHeader/ContentHeader"
import ContentBox from "components/ContentBox/ContentBox"

export default class PageLegal extends Component {
  render() {
    return (
      <div className={styles.root}>
        <ContentHeader title={<span>Mentions légales</span>} />
        <div className={classNames(ContentHeader.above, ContentBox.mt100)}>
          <ContentBox>
            <div className={styles.border}>
              <div className={styles.company}>
                <p className={styles.firstline}>
                  <span>Molotov</span> - S.A.S. au capital de 400 791€
                </p>
                <p className={styles.company}>103 rue de Grenelle</p>
                <p className={styles.company}>75007 Paris - France</p>
              </div>
              <h3 className={styles.header}>Contacts</h3>
              <p className={styles.email}>
                Email général
                <span className={styles.alignRight}>
                  <a href="mailto:hello@molotov.tv">hello@molotov.tv</a>
                </span>
              </p>
              <p className={styles.email}>
                Presse française
                <span className={styles.alignRight}>
                  <a href="mailto:press@molotov.tv">presse@molotov.tv</a>
                </span>
              </p>
              <p className={styles.email}>
                Presse internationale
                <span className={styles.alignRight}>
                  <a href="mailto:pr@molotov.tv">pr@molotov.tv</a>
                </span>
              </p>
              <p className={styles.email}>
                <Link to={consts.routes.careers}>Rejoindre la team</Link>
              </p>
              <h3 className={styles.header}>Fondateurs</h3>
              <p className={styles.founders}>
                Pierre Lescure, JeanDavid Blanc,
                <br /> Jean-Marc Denoual
              </p>
              <h3 className={styles.header}>Conditions</h3>
              <p className={styles.founders}>
                En vous inscrivant, vous acceptez les <Link to={consts.routes.gtu}>Conditions d'utilisation</Link>, la{" "}
                <Link to={consts.routes.privacy}>Politique de confidentialité</Link> et l’
                <Link to={consts.routes.cookies}>Utilisation des cookies</Link> de Molotov.
              </p>
            </div>
          </ContentBox>
        </div>
      </div>
    )
  }
}

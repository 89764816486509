import React, { Component } from "react"
import PropTypes from "prop-types"

import RadioButton from "components/RadioButton/RadioButton"

export default class RadioGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    buttonStyle: PropTypes.string,
    rootStyle: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        valueDisplayed: PropTypes.string,
      })
    ).isRequired,
    selectedValue: PropTypes.string,
    radioButtonStyle: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => {},
    buttonStyle: "",
    rootStyle: "",
    selectedValue: "",
  }

  state = { selectedValue: this.props.selectedValue }

  onChange = value => {
    this.setState({ selectedValue: value })

    this.props.onChange(value)
  }

  render() {
    const { name, values, buttonStyle, rootStyle, radioButtonStyle } = this.props

    const selectedValue = this.state.selectedValue

    return (
      <div className={rootStyle}>
        {values.map((valueObj, index) => {
          const { value, valueDisplayed, subtitle } = valueObj

          return (
            <RadioButton
              key={index}
              name={name}
              value={value}
              valueDisplayed={valueDisplayed}
              subtitle={subtitle}
              checked={selectedValue === value}
              customStyle={buttonStyle}
              onChange={this.onChange}
              rootStyle={radioButtonStyle}
            />
          )
        })}
      </div>
    )
  }
}

import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"

import CheckboxButton from "components/CheckboxButton/CheckboxButton"
import Button from "components/Button/Button"

import formatterHelper from "helpers/formatter"

import TrackingSegment from "managers/segmentManager"

import consts from "consts"
import styles from "./index.css"
import { executeAdvancedApiAction } from "actions/api"
import { updateProductOptins } from "actions/products"

function buildStateFromProps(props) {
  return {
    checkboxes: props.optins.interaction.checkboxs || [],
    buttons: props.optins.interaction.buttons,
  }
}

@connect()
export default class ProductInterstitial extends React.Component {
  static propTypes = {
    customStyle: PropTypes.string,
    dispatch: PropTypes.func,
    footer: PropTypes.object,
    id: PropTypes.string,
    onSubmit: PropTypes.func,
    optins: PropTypes.object,
  }

  state = buildStateFromProps(this.props)

  onChange = (index, value) => {
    this.setState({
      checkboxes: [
        ...this.state.checkboxes.slice(0, index),
        {
          ...this.state.checkboxes[index],
          default_status: value,
        },
        ...this.state.checkboxes.slice(index + 1),
      ],
    })
  }

  getFormattedText = (text, forceBlack = false) => {
    if (text && typeof text === "object") {
      // force black text to prevent issue on mobile webview
      // where we forward their molotov agent
      if (forceBlack && Array.isArray(text.parts)) {
        text.parts = text.parts.map(part => {
          part.color = "#000"
          return part
        })
      }
      return formatterHelper.styled(text.format, text.parts)
    }
    return text
  }

  onActionSubmitSuccess = () => {
    this.props.onSubmit()
  }

  onActionSubmitError = error => {
    this.setState({ error })
  }

  onClick = button => {
    let isButtonDisabled = false

    Array.isArray(this.state.checkboxes) &&
      this.state.checkboxes.forEach(checkbox => {
        if (checkbox.required && !checkbox.default_status) {
          isButtonDisabled = true
          this.setState({
            error: {
              user_message: this.getFormattedText(checkbox.required_error_formatter),
            },
          })
        }
      })

    if (!isButtonDisabled) {
      button.on_click.forEach((actionName, index) => {
        const isLastOnClickAction = index === button.on_click.length - 1
        const continuationCallback = isLastOnClickAction ? this.onActionSubmitSuccess : consts.NoOp
        let action = get(this.props.optins, "interaction.actions." + actionName)
        if (action) {
          switch (action.type) {
            case "show_interstitial":
              if (action.payload) {
                this.props.dispatch(updateProductOptins(this.props.id, action.payload))
              }
              break

            case "submit":
              let params = {}
              Array.isArray(this.state.checkboxes) &&
                this.state.checkboxes.map(checkbox => {
                  params[checkbox.index] = checkbox.default_status
                })
              this.props.dispatch(executeAdvancedApiAction(action.url, action.method, params, continuationCallback, this.onActionSubmitError))
              break

            default:
              continuationCallback()
              break
          }
        }
      })
    }
  }

  trackInterstitial = () => {
    if (this.props.optins && this.props.optins.subtype === "nfl_optins") {
      TrackingSegment.trackPage("optins", {
        optin_name: "nfl",
      })
    }
  }

  componentDidMount() {
    this.trackInterstitial()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.optins !== this.props.optins) {
      this.setState(buildStateFromProps(this.props))
      this.trackInterstitial()
    }
  }

  render() {
    const { footer, optins, id } = this.props

    let title = (optins && optins.title_formatter && this.getFormattedText(optins.title_formatter, true)) || null
    let message = (optins && optins.message_formatter && this.getFormattedText(optins.message_formatter, true)) || null

    return (
      <div className={this.props.customStyle}>
        <div className={styles.content}>
          <p className={styles.title}> {title} </p>
          {this.state.error && <div className={styles.error}>{this.state.error.user_message}</div>}
          {message && <p className={styles.message}> {message} </p>}
          {Array.isArray(this.state.checkboxes) &&
            this.state.checkboxes.map((checkbox, index) => {
              let checkboxDescription = this.getFormattedText(checkbox.title_formatter, true)
              return (
                <label className={styles.rule} key={checkbox.index}>
                  <CheckboxButton
                    name={checkbox.index}
                    checked={checkbox.default_status}
                    value={index}
                    required={checkbox.required}
                    onChange={this.onChange}
                    offerId={id}
                  />
                  <p className={styles.checkboxDescription}>{checkboxDescription}</p>
                </label>
              )
            })}
        </div>
        {Array.isArray(this.state.buttons) &&
          this.state.buttons.map((button, index) => {
            let isDisabled = false

            this.state.checkboxes &&
              this.state.checkboxes.forEach(checkbox => {
                if (checkbox.required && !checkbox.default_status) {
                  isDisabled = true
                }
              })

            return (
              <Button
                key={index}
                color={isDisabled ? Button.colors.greyIron : Button.colors.yellowFull}
                customStyle={classNames(styles.submitButton)}
                onClick={() => this.onClick(button)}
                value={button.title}
              />
            )
          })}

        <div className={styles.footer}>{footer}</div>
      </div>
    )
  }
}

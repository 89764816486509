import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

// Components
import Button from "components/Button/Button"
import Input from "components/Input/Input"
import InputSubmit from "components/InputSubmit/InputSubmit"
import FormFooter from "./FormFooter"
import FormBottomMessage from "./FormBottomMessage"
import PaypalButton from "components/PaypalButton/PaypalButton"
import paypalAbTesting from "selectors/abTesting"
import { getPaypalAbTesting } from "actions/abTesting"

// Actions
import { postPayment, resetPasswordError } from "actions/mtvPay"
import { postDspPayment } from "actions/dspSub"
import { open as openModal } from "actions/modal"

// Selectors
import { selectMtvPayPaymentForProduct } from "selectors/mtvPay"
import { selectDspPaymentForProduct } from "selectors/dspPayment"

// Helpers
import formatterHelper from "helpers/formatter"
import apiAction from "helpers/apiAction"
import { getBrowserInfos } from "helpers/payment"

import RecurlyPaypalButton from "components/RecurlyPaypalButton/RecurlyPaypalButton"

import i18n from "consts/i18n"
import modalTypes from "containers/Modal/types"
import styles from "./index.css"

// This is ugly since we're not supposed to hardcode those action names in our code but
// the API does not offer any mechanism to distinguish buttons and we need to do it to build
// this page (in order to know which button should be placed and styled as specified by mockups).
const PAYMENT_FORM_ONCLICK_IDENTIFIER = "payment_pay"
const PASSWORD_FORM_ONCLICK_IDENTIFIER = "payment_card"
const CANCEL_FORM_ONCLICK_IDENTIFIER = "cancel"

@connect((state, ownProps) => ({
  mtvPayPasswordError: selectMtvPayPaymentForProduct(state, ownProps.equivalenceCode).passwordError,
  dspPasswordError: selectDspPaymentForProduct(state, ownProps.equivalenceCode).passwordError,
  isDesktop: state.appSettings.device === "desktop",
  abTesting: paypalAbTesting(state),
}))
export default class PasswordForm extends Component {
  static propTypes = {
    discount: PropTypes.object,
    discountedPrice: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    equivalenceCode: PropTypes.string.isRequired,
    footer: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    mtvPayPasswordError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    dspPasswordError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    passwordForm: PropTypes.object.isRequired,
    paymentPending: PropTypes.bool,
    tracker: PropTypes.func.isRequired,
    paypalInfos: PropTypes.object,
    onSuccess: PropTypes.func,
    isDesktop: PropTypes.bool,
  }

  state = {
    password: "",
  }

  passwordInputRef = React.createRef()

  static fetchData(dispatch) {
    return [dispatch(getPaypalAbTesting())]
  }

  getPaymentAction = () => {
    return this.props.passwordForm.interaction.actions[PAYMENT_FORM_ONCLICK_IDENTIFIER]
  }

  getChangeCardAction = () => {
    return this.props.passwordForm.interaction.actions[PASSWORD_FORM_ONCLICK_IDENTIFIER]
  }

  getCancelAction = () => {
    return this.props.passwordForm.interaction.actions[CANCEL_FORM_ONCLICK_IDENTIFIER]
  }

  isPasswordEmpty = () => {
    return !this.state.password
  }

  onCancel = () => {
    this.props.dispatch(apiAction.executeAction(this.getCancelAction()))
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onSubmit = e => {
    e && e.preventDefault()

    if (this.props.paymentPending || this.isPasswordEmpty()) {
      return
    }

    const paymentAction = this.getPaymentAction()
    if (this.props.passwordForm.is_dsp) {
      this.props.dispatch(postDspPayment(paymentAction, { password: this.state.password }, this.props.equivalenceCode))
    } else {
      this.props.dispatch(
        postPayment(
          paymentAction,
          {
            password: this.state.password,
            browser_info: getBrowserInfos(),
          },
          this.props.equivalenceCode
        )
      )
    }
  }

  submitRecurly = token => {
    const paymentAction = this.getPaymentAction()
    this.props.dispatch(
      postPayment(
        paymentAction,
        {
          token: token.id,
          browser_info: getBrowserInfos(),
        },
        this.props.equivalenceCode
      )
    )
  }

  onPasswordChange = password => {
    this.setState({ password })

    if (this.props.mtvPayPasswordError || this.props.dspPasswordError) {
      this.props.dispatch(resetPasswordError(this.props.equivalenceCode))
    }
  }

  onCardChange = () => {
    this.props.dispatch(apiAction.executeAction(this.getChangeCardAction()))
  }

  onForgotPassword = () => {
    this.props.dispatch(openModal(modalTypes.resetPassword, null, null, false))
  }

  componentDidMount() {
    this.constructor.fetchData(this.props.dispatch, this.props, this.props)

    if (this.passwordInputRef.current) {
      this.passwordInputRef.current.focus()
    }
    this.props.tracker("password_confirmation", this.props.equivalenceCode)
  }

  componentWillUnmount() {
    this.props.dispatch(resetPasswordError(this.props.equivalenceCode))
  }

  render() {
    const { discount, discountedPrice, mtvPayPasswordError, dspPasswordError, passwordForm, paymentPending, isDesktop } = this.props

    const { password } = this.state

    if (passwordForm.error) {
      return <div className={styles.genericErrorContainer}>{passwordForm.error.message}</div>
    }

    const buttons = passwordForm.interaction.buttons
    const paymentButton = apiAction.findButtonByOnClick(buttons, PAYMENT_FORM_ONCLICK_IDENTIFIER)
    const changeCardButton = apiAction.findButtonByOnClick(buttons, PASSWORD_FORM_ONCLICK_IDENTIFIER)
    const cancelButton = apiAction.findButtonByOnClick(buttons, CANCEL_FORM_ONCLICK_IDENTIFIER)

    const error = mtvPayPasswordError || dspPasswordError

    const labelWithPrice = !passwordForm?.reinsurance?.price?.parts[0].title.includes(passwordForm?.reinsurance?.label?.parts[0].title)

    const isAbTestingEnabled = get(this.props, ["abTesting", "data", "show_paypal_recurly"])
    const isAbTestingPending = get(this.props, ["abTesting", "pending"], false)

    const isPaypalEnabled =
      !window.Android &&
      this.props.paypalInfos &&
      !!Object.keys(this.props.paypalInfos).length &&
      isAbTestingPending === false &&
      isAbTestingEnabled !== undefined

    return !paymentPending ? (
      <form onSubmit={this.onSubmit} className={styles.passwordForm} data-test="form-payment-password">
        {passwordForm.title_formatter ? (
          <div className={styles.passwordTitle}>{formatterHelper.styledFormatter(passwordForm.title_formatter)}</div>
        ) : null}

        {/*{passwordForm?.reinsurance?.label && (*/}
        {/*  <div className={classNames(styles.label, styles.centeredText)}>{formatterHelper.styledFormatter(passwordForm.reinsurance.label)}</div>*/}
        {/*)}*/}

        {!isDesktop && labelWithPrice && (
          <div className={styles.row}>
            <div className={classNames(styles.label, styles.centeredText)}>{formatterHelper.styledFormatter(passwordForm.reinsurance.label)}</div>
            <div className={classNames(styles.tbccPrice, styles.centeredText)}>{formatterHelper.styledFormatter(passwordForm.reinsurance.price)}</div>
          </div>
        )}

        {!isDesktop && !labelWithPrice && passwordForm?.reinsurance?.price && (
          <div className={classNames(styles.tbccPrice, styles.centeredText)}>{formatterHelper.styledFormatter(passwordForm.reinsurance.price)}</div>
        )}

        {!isDesktop && passwordForm?.reinsurance?.price_subtitle && (
          <div className={classNames(styles.subtitle, styles.centeredText)}>
            {formatterHelper.styledFormatter(passwordForm.reinsurance.price_subtitle)}
          </div>
        )}

        {changeCardButton ? (
          <div className={styles.changeCard}>
            <span>{changeCardButton.subtitle}</span>
            <button className={styles.changeButton} type="button" onClick={this.onCardChange}>
              {changeCardButton.title}
            </button>
          </div>
        ) : null}

        {passwordForm.message_top_formatter ? (
          <div className={styles.passwordMessage}>{formatterHelper.styledFormatter(passwordForm.message_top_formatter)}</div>
        ) : null}

        <div className={styles.formGroup}>
          {error ? (
            <div className={classNames(styles.label, styles.errorLabel)}>{error}</div>
          ) : (
            <div className={styles.label}>{i18n.payment.passwordForm.password}</div>
          )}
          <Input
            customStyle={classNames(styles.input, styles.passwordInput)}
            error={false}
            errorMessage={""}
            onChange={this.onPasswordChange}
            value={password}
            type="password"
            inputRef={this.passwordInputRef}
          />

          <button className={styles.forgotButton} type="button" onClick={this.onForgotPassword}>
            {i18n.payment.forgotPassword}
          </button>
        </div>

        <FormBottomMessage messageFormatter={passwordForm.message_bottom_formatter} />

        <div className={styles.formGroup}>
          <InputSubmit
            disabled={this.isPasswordEmpty()}
            customStyle={classNames(InputSubmit.types.yellowFull, styles.submit, { [styles.buttonPending]: paymentPending })}
            value={discountedPrice === 0 ? "Essayer gratuitement" : paymentButton.title}
          />
        </div>

        {isPaypalEnabled && (
          <div className={styles.paypalPayment}>
            Payer avec PayPal :{" "}
            {isAbTestingEnabled ? (
              <RecurlyPaypalButton submitRecurly={this.submitRecurly} />
            ) : (
              <PaypalButton
                {...this.props.paypalInfos}
                onCancel={this.onCancel}
                onSuccess={this.props.onSuccess}
                buttonStyle={styles.paypalButton}
                customBtnStyle={{ label: "pay" }}
              />
            )}
          </div>
        )}

        {cancelButton ? (
          <div className={classNames(styles.formGroup, styles.cancelButton)}>
            <Button
              type="button"
              onClick={this.onCancel}
              customStyle={classNames(Button.colors.greyFull, styles.cancel)}
              value={cancelButton.title || i18n.cancel}
            />
          </div>
        ) : null}

        {!passwordForm.is_dsp && <FormFooter footerFormatter={passwordForm.footer_formatter} isDesktop={this.props.isDesktop} />}
      </form>
    ) : (
      <div className={styles.passwordForm}>
        <div className={styles.formHeader}>
          {passwordForm.title_formatter ? (
            <div className={styles.passwordTitle}>{formatterHelper.styledFormatter(passwordForm.title_formatter)}</div>
          ) : null}
        </div>

        <div className={styles.loadingContent}>
          <div className={styles.loader} />
          {discount ? "Validation" : "Paiement"} en cours
        </div>

        {!passwordForm.is_dsp && <FormFooter footerFormatter={passwordForm.footer_formatter} isDesktop={this.props.isDesktop} />}
      </div>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./../icon.css"

const IconBurger = ({ customStyle = null }) => (
  <svg viewBox="0 0 24 16" className={classNames(customStyle, styles.fill)}>
    <path d="M0 0h24v2H0V0zm0 14h24v2H0v-2zm0-7h24v2H0V7z" fillRule="evenodd" />
  </svg>
)

IconBurger.propTypes = {
  customStyle: PropTypes.string,
}

export default IconBurger

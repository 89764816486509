import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import InputSubmit from "components/InputSubmit/InputSubmit"
import PageNotFound from "containers/PageErrors/PageNotFound"

import { reinitializeEmail as resetEmail, resetEmailForm, clearEmailForm } from "actions/email"

import helperFormatter from "helpers/formatter"

import { selectQuery } from "selectors/location"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect((state, ownProps) => ({
  emailForm: state.forms.email,
  query: selectQuery(ownProps),
}))
export default class PageResetEmail extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    dispatch: PropTypes.func,
    emailForm: PropTypes.object.isRequired,
    query: PropTypes.object,
  }

  onSubmit = ev => {
    ev.preventDefault()
    const email = this.props.query.email
    this.props.dispatch(resetEmail(email))
  }

  componentDidMount() {
    this.props.dispatch(resetEmailForm())
  }

  componentWillUnmount() {
    this.props.dispatch(clearEmailForm())
  }

  render() {
    const { customStyle, emailForm } = this.props

    const email = this.props.query.email

    if (!email) {
      return <PageNotFound />
    }

    return (
      <div className={classNames(styles.root, customStyle)}>
        <div className={styles.content}>
          <h3 className={styles.title}>{i18n.resetEmailPage.title}</h3>
          {!emailForm.success && (
            <form onSubmit={this.onSubmit}>
              <p className={styles.subText}>{helperFormatter.styled(i18n.resetEmailPage.subText, [{ title: email, bold: true, color: "black" }])}</p>
              <InputSubmit
                type="submit"
                customStyle={classNames(InputSubmit.types.yellowFull, {
                  [styles.button]: true,
                  [styles.disabled]: emailForm.pending,
                })}
                value={i18n.send}
              />
            </form>
          )}
          {emailForm.success && (
            <div className={styles.successContent}>
              <p>{i18n.resetEmailPage.successText}</p>
              <div className={styles.successEmail}>{email}</div>
              <p className={styles.successText}>{i18n.resetEmailPage.successDescription}</p>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div>{emailForm.success ? i18n.resetEmailPage.successHelp : i18n.resetEmailPage.help}</div>
        </div>
      </div>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon, { IconTypes } from "components/Icon/Icon"

import styles from "./toolTip.css"

const ToolTip = ({ rootStyle = styles.position, title = null, textArray = [], onCloseClick = null, imageSVG = null }) => (
  <span className={classNames(styles.root, rootStyle)}>
    {onCloseClick && <Icon key="icon" type={IconTypes.close} rootStyle={styles.close} iconStyle={styles.closeIcon} onClick={onCloseClick} />}
    {title && <span className={styles.title}>{title}</span>}
    {imageSVG && <div className={styles.image}>{imageSVG}</div>}
    {textArray.map((text, index) => {
      return (
        <span className={styles.text} key={index}>
          {text}
        </span>
      )
    })}
  </span>
)

ToolTip.propTypes = {
  imageSVG: PropTypes.object,
  onCloseClick: PropTypes.func,
  rootStyle: PropTypes.string,
  title: PropTypes.string,
  textArray: PropTypes.array,
}

export default ToolTip

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Button from "components/Button/Button"

import styles from "./passwordRequired.css"

import consts from "consts"
import i18n from "consts/i18n"

const PasswordRequired = ({ customStyle, onCancel = null, onAccept = consts.NoOp, children }) => {
  const style = classNames(styles.root, customStyle)
  return (
    <div className={style}>
      {children}
      <Button
        color={Button.colors.yellowFull}
        customStyle={classNames(styles.leftButton)}
        onClick={onAccept}
        value={i18n.modalSetPassword.createPassword}
      />
      {onCancel && <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={onCancel} value={i18n.cancel} />}
    </div>
  )
}

PasswordRequired.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  customStyle: PropTypes.string,
}

export default PasswordRequired

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"

import FormGroup from "components/FormGroup/FormGroup"
import FormLabel from "components/FormGroup/FormLabel/FormLabel"
import Input from "components/Input/Input"
import Separator from "components/Separator/Separator"
import Button from "components/Button/Button"
import Alert from "components/Alert/Alert"
import AlertTypes from "components/Alert/types"
import Loader from "components/Loader/Loader"
import PasswordRequired from "components/PasswordRequired/PasswordRequired"
import Icon, { IconTypes } from "components/Icon/Icon"
import SectionTitle from "components/SectionTitle/SectionTitle"

import { check as checkPassword, resetPasswordForm } from "actions/password"
import { deleteAccount } from "actions/account"
import { logout } from "actions/session"
import { get as getMetrics } from "actions/recordMetrics"
import { get as getSubscriptions } from "actions/subscriptions"

import selectAuthentication from "selectors/authentication"
import selectMetrics from "selectors/metrics"
import selectSubscriptions from "selectors/subscriptions"
import selectAccount from "selectors/account"

import i18n from "consts/i18n"

import styles from "./index.css"

@connect(
  state => ({
    authentication: selectAuthentication(state),
    metrics: selectMetrics(state),
    statePassword: state.forms.password,
    subscriptions: selectSubscriptions(state),
    account: selectAccount(state),
  }),
  dispatch => ({
    checkPassword: (password, onSuccess) => {
      dispatch(checkPassword(password, onSuccess))
    },
    getMetrics: () => {
      dispatch(getMetrics())
    },
    getSubscriptions: () => {
      dispatch(getSubscriptions())
    },
    resetPasswordForm: () => {
      dispatch(resetPasswordForm())
    },
    deleteAccount: onSuccess => {
      dispatch(deleteAccount(onSuccess))
    },
    logout: () => {
      dispatch(logout())
    },
  })
)
export default class ModalDeleteAccount extends Component {
  static propTypes = {
    checkPassword: PropTypes.func.isRequired,
    resetPasswordForm: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    authentication: PropTypes.object,
    statePassword: PropTypes.object,
    metrics: PropTypes.object,
    getMetrics: PropTypes.func.isRequired,
    subscriptions: PropTypes.object,
    getSubscriptions: PropTypes.func.isRequired,
    renderModes: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  }

  static defaultProps = {
    close: () => {},
    account: {
      hasPassword: true,
    },
  }

  state = {
    password: "",
  }

  submit = () => {
    const { checkPassword, deleteAccount, logout } = this.props

    const { password } = this.state

    if (password.length > 0) {
      checkPassword(password, () => {
        deleteAccount(() => {
          this.onClose()
          logout()
        })
      })
    }
  }

  onKeyPress = event => {
    if (event.keyCode === 13) {
      this.submit()
    }
  }

  onClose = () => {
    const { close, resetPasswordForm } = this.props

    resetPasswordForm()
    close()
  }

  getDeleteComponent = () => {
    const { password } = this.state

    const { authentication, statePassword, metrics, subscriptions } = this.props

    return (
      <div>
        {(metrics.pending || subscriptions.pending) && <Loader centerInParent={true} />}

        {!metrics.pending && !subscriptions.pending && (
          <div className={styles.main}>
            <b>Avant de procéder à la suppression de votre compte Molotov, veuillez prendre connaissance des points suivants :</b>
            <br />
            <br />
            <b>1/ Supprimer un compte Molotov est différent d’une résiliation de souscription.</b>
            <br />
            <br />
            Pour toute demande de résiliation, veuillez vous référer à l’article suivant :{" "}
            <a
              href="https://aide.molotov.tv/hc/fr/articles/6040269952657-Comment-r%C3%A9silier-une-option-de-mon-abonnement-%C2%A0"
              className={styles.link}
              target={"_blank"}
              aria-label="Comment résilier une option de mon abonnement"
            >
              {" "}
              Comment résilier une option de mon abonnement ?
            </a>
            <br />
            <br />
            <b>2/ Sachez que cette procédure est définitive et qu’elle entraînera les actions suivantes, sans rétroactivité possible :</b>
            <ul className={styles.list}>
              <li className={styles.listElements}>Vous ne pourrez définitivement plus vous connecter à ce compte Molotov ;</li>
              <li className={styles.listElements}>
                L’ensemble de vos enregistrements et les personnalités que vous suivez seront définitivement supprimés ;
              </li>
              <li className={styles.listElements}>
                Vos options souscrites sur Molotov avec votre carte bancaire ou via PayPal seront automatiquement résiliées.
              </li>
            </ul>
            <br />
            <br />
            <b>
              Attention, si vous payez votre abonnement avec le moyen de paiement enregistré sur votre compte Apple, Google ou Amazon, vos options
              resteront actives car la facturation est gérée par des services de facturation externes à Molotov.
            </b>
            <br />
            <br />
            <b>
              Avant de demander la suppression de votre compte, vous devez donc résilier toute souscription active directement depuis votre compte
              Apple, Google ou Amazon.
            </b>
            <br />
            <br />
            <b>
              Pour en apprendre plus sur comment résilier votre abonnement sur ces plateformes de paiement, veuillez vous référer à l’article suivant
              :{" "}
              <a
                href="https://aide.molotov.tv/hc/fr/articles/6040269952657-Comment-r%C3%A9silier-une-option-de-mon-abonnement-%C2%A0"
                className={styles.link}
                target={"_blank"}
                aria-label="Comment résilier une option de mon abonnement"
              >
                {" "}
                Comment résilier une option de mon abonnement ?
              </a>
            </b>
            <Alert type={AlertTypes.alert} customStyle={styles.alert}>
              <div>
                <Icon type={IconTypes.alert} iconStyle={styles.iconAlert} />

                <div>{i18n.modalDeleteAccount.message}</div>

                {subscriptions.data.map((subscription, index) => {
                  return <div key={index}>{subscription.isApple && <p className={styles.paragraph}>{i18n.modalDeleteAccount.appleOffer}</p>}</div>
                })}
              </div>
            </Alert>
            <FormGroup customStyle={styles.group}>
              <FormLabel title={i18n.modalDeleteAccount.enter_password} />
              <Input
                type="password"
                customStyle={styles.input}
                onChange={value => {
                  this.setState({ password: value })
                }}
              />
            </FormGroup>
            {statePassword.error && (
              <Alert customStyle={styles.alert}>
                <span>{statePassword.error.message}</span>
              </Alert>
            )}
            {authentication.error && (
              <Alert customStyle={styles.alert}>
                <span>{authentication.error.message}</span>
              </Alert>
            )}
            <Separator customStyle={styles.separator} />
            <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={this.onClose} value={i18n.cancel} />
            <Button
              color={Button.colors.redFull}
              customStyle={classNames(styles.rightButton, {
                [styles.disabled]: password.length === 0,
              })}
              onClick={this.submit}
              value={i18n.delete}
            />
          </div>
        )}
      </div>
    )
  }

  getModalContent = () => {
    const {
      renderModes,
      account: { hasPassword },
    } = this.props
    if (hasPassword) {
      return this.getDeleteComponent()
    } else {
      return (
        <PasswordRequired onCancel={renderModes.defaultMode} onAccept={this.onAccept}>
          <div className={styles.passwordRequiredMessage}>{i18n.modalDeleteAccount.needPassword}</div>
        </PasswordRequired>
      )
    }
  }

  getDspWording = () => (
    <>
      <div>Vous avez souscrit à Molotov dans le cadre de votre abonnement {this.props.account.dsp.toUpperCase()}.</div>
      <br />
      <br />
      <div>
        Pour supprimer votre compte vous devez contacter le service client Molotov à{" "}
        <a href="mailto:help@molotov.tv" aria-label="Support">
          help@molotov.tv
        </a>
      </div>
      <Separator customStyle={styles.separator} />
      <Button color={Button.colors.greyFull} customStyle={styles.button} onClick={this.onClose} value={i18n.cancel} />
    </>
  )

  onAccept = () => {
    this.props.renderModes.setPasswordMode(true)
  }

  componentDidMount() {
    if (!this.props.account.dsp) {
      window.addEventListener("keypress", this.onKeyPress)
      this.props.getMetrics()
      this.props.getSubscriptions()
    }
  }

  componentWillUnmount() {
    if (!this.props.account.dsp) {
      window.removeEventListener("keypress", this.onKeyPress)
      this.props.resetPasswordForm()
    }
  }

  render() {
    return (
      <div>
        <div>
          <SectionTitle title={i18n.modalDeleteAccount.title} mobileTitle={i18n.modalDeleteAccount.mobileTitle} isLogout={true} />
          {this.props.account.dsp ? this.getDspWording() : this.getModalContent()}
        </div>
      </div>
    )
  }
}

import dateHelper from "helpers/date"

const helpers = {
  countBookmark: function(data) {
    return data.filter(entry => {
      return entry.bookmark.selected && !entry.isParent
    }).length
  },

  getBookmarkFromId: function(data, _id) {
    return data.find(({ id }) => id === _id)
  },

  allChildrenSelected: function(data, bookmark) {
    const parentId = bookmark.id
    const children = data.filter(({ parent }) => parent === parentId)
    const childrenSelected = children.filter(({ bookmark }) => !!bookmark.selected)
    return children.length === childrenSelected.length
  },

  isAllBookmarksChecked: function(data) {
    return data.reduce((acc, item) => {
      return acc && item.bookmark.selected
    }, true)
  },

  getNewDataForToggle: function(state, action) {
    const data = state.data

    if (!data) {
      return data
    }

    let newData = data

    if (action.value === "all") {
      return data.map(item => ({
        ...item,
        bookmark: {
          ...item.bookmark,
          selected: action.checked,
        },
      }))
    }

    const bookmark = helpers.getBookmarkFromId(data, action.value)
    const list = []

    if (!bookmark) {
      return newData
    }

    list.push(bookmark.id)

    if (bookmark.isParent) {
      data.map(entry => {
        if (entry.parent === bookmark.id) {
          list.push(entry.id)
        }
      })
    } else if (bookmark.parent && !action.checked) {
      list.push(bookmark.parent)
    }

    newData = data.map(entry => {
      let newEntry = entry
      let newSelectedValue

      newSelectedValue = action.checked && list.indexOf(entry.id) >= 0 ? true : !action.checked && list.indexOf(entry.id) >= 0 ? false : undefined

      if (newSelectedValue !== undefined) {
        newEntry = {
          ...entry,
          bookmark: {
            ...entry.bookmark,
            selected: newSelectedValue,
          },
        }
      }

      return newEntry
    })

    return newData
  },

  formatData: function(data) {
    const dataFormated = []

    data.map(({ broadcasted_at, channel_name, id, duration, items, ...rest }) => {
      // eslint-disable-line camelcase
      const parentId = id

      dataFormated.push({
        id,
        bookmark: {
          id,
          selected: false,
        },
        isParent: !!items,
        duration: Math.round(duration / 60),
        channelName: channel_name,
        /*eslint-disable camelcase */
        broadcastedAt: broadcasted_at,
        broadcastedAtFormatted: broadcasted_at ? dateHelper.format(broadcasted_at * 1000) : " - ",
        /*eslint-disable camelcase */
        ...rest,
      })

      if (items) {
        items.map(({ broadcasted_at, id, duration, ...rest }) => {
          dataFormated.push({
            id,
            bookmark: {
              id,
              selected: false,
            },
            category: "",
            duration: Math.round(duration / 60),
            channelName: "",
            broadcastedAt: broadcasted_at,
            broadcastedAtFormatted: broadcasted_at ? dateHelper.format(broadcasted_at * 1000) : " - ",
            parent: parentId,
            ...rest,
          })
        })
      }
    })

    return dataFormated
  },
}

export default helpers

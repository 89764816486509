import { isArray } from "lodash"

import apiAction from "helpers/apiAction"

import consts from "consts"
import { API_ACTION_ERROR } from "consts/actions"

export function executeAdvancedApiAction(url, method = "GET", extraParams = {}, onSuccess = consts.NoOp, onError = consts.NoOp) {
  const params = method === "GET" ? { params: extraParams } : { body: extraParams }

  return {
    request: {
      base: "",
      url,
      method,
      ...params,
    },

    onSuccess,
    onError,
  }
}

// Execute an array of actions sent by the API
export function executeApiActions(actions, params = {}) {
  return dispatch => {
    isArray(actions) &&
      actions.forEach(action => {
        dispatch(apiAction.executeAction(action, null, params))
      })
  }
}

function apiActionStatus(action, response, params, status) {
  return {
    type: status,
    payload: {
      action,
      params,
      response,
    },
  }
}

export function apiActionError(action, response, params) {
  return apiActionStatus(action, response, params, API_ACTION_ERROR)
}

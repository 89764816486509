import React from "react"

import styles from "./../icon.css"

const IconApple = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className={styles.appleIcon}>
    <path
      fillRule="evenodd"
      d="M20.405 16.448a11.458 11.458 0 01-1.135 2.04c-.596.85-1.085 1.44-1.461 1.767-.584.536-1.21.811-1.879.827-.48 0-1.06-.137-1.735-.414-.677-.277-1.3-.413-1.868-.413-.597 0-1.237.136-1.921.413-.686.277-1.238.422-1.66.436-.643.027-1.283-.255-1.922-.85-.407-.355-.917-.965-1.528-1.829-.656-.922-1.195-1.992-1.617-3.211C3.227 13.897 3 12.622 3 11.387c0-1.415.306-2.635.918-3.657A5.384 5.384 0 015.84 5.786a5.171 5.171 0 012.599-.734c.51 0 1.179.158 2.01.468.829.311 1.36.469 1.594.469.175 0 .766-.184 1.768-.552.948-.341 1.748-.483 2.403-.427 1.776.143 3.11.843 3.997 2.105-1.588.962-2.374 2.31-2.358 4.038.014 1.347.503 2.468 1.463 3.358.435.413.921.732 1.462.958-.117.34-.241.666-.373.979zM16.333.422c0 1.056-.386 2.041-1.155 2.954-.927 1.084-2.05 1.71-3.266 1.612a3.286 3.286 0 01-.024-.4c0-1.014.44-2.098 1.224-2.985a4.714 4.714 0 011.492-1.12C15.206.19 15.775.028 16.31 0c.015.141.022.282.022.422z"
    />
  </svg>
)

export default IconApple

import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./select.css"

export default class Select extends Component {
  static propTypes = {
    value: PropTypes.string,
    values: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    selectedValue: PropTypes.any,
    customStyle: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    stateless: PropTypes.bool,
  }

  static defaultProps = {
    customStyle: "",
    placeholder: "",
    onChange: () => {},
    error: false,
  }

  state = {
    value: this.props.selectedValue,
  }

  onChange = event => {
    const value = event.target.value

    if (!this.props.stateless) {
      this.setState({ value: value })
    }
    this.props.onChange(value)
  }

  render() {
    const { values, customStyle, placeholder, name = null, error, stateless } = this.props
    const { value } = this.state

    return (
      <span className={classNames(styles.root, customStyle)}>
        <select
          className={classNames(styles.select, { [styles.error]: error })}
          onChange={this.onChange}
          value={stateless ? this.props.value : value}
          name={name}
        >
          <option>{placeholder}</option>
          {values.length > 0 &&
            values.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              )
            })}
        </select>
      </span>
    )
  }
}

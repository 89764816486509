import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./contentBox.css"
import { exposeStyles } from "helpers/components"

const ContentBox = ({ className, children, leftAligned }) => (
  <div
    className={classNames(className, styles.root, {
      [styles.leftAligned]: leftAligned,
    })}
  >
    {children}
  </div>
)

ContentBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  leftAligned: PropTypes.bool,
}

export default exposeStyles(ContentBox, styles)
